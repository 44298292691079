.switch {
  display: flex;
  align-items: center;
  font-size: 0.8571em;

  &-control {
    margin-right: 0.25em;
    transform: translate(-4px, -2px);

    rect:first-child {
      fill: color('charcoal-light');
      transition: fill 0.125s ease-out;
    }

    rect:last-child {
      stroke: var(--current-color);
    }

    circle {
      transition: cx 0.125s ease-out;
    }

    &.is-selected {
      rect:first-child {
        fill: var(--current-color);
      }
    }
  }
}
