/* stylelint-disable property-no-vendor-prefix -- Fix bug with autoprefixer, gradients and masks */

/**
 * Top left & right rounded corners
 *
 * @param bool $handle-root-scrollbar  Whether to withdraw the root scrollbar width from the right corner position
 * @param css-unit $top-position  The position of the corners from the top of the screen
 */
@mixin top-corners($handle-root-scrollbar: true, $top-position: var(--app-outline-width)) {
  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: $top-position;
    width: var(--app-outline-radius);
    height: var(--app-outline-radius);
    z-index: 100;
    background-color: var(--app-outline-color);
  }

  &::before {
    /*! autoprefixer: off */
    left: var(--app-outline-width);
    mask: radial-gradient(var(--app-outline-radius) at 100% 100%, #0000 100%, #000);
    -webkit-mask: radial-gradient(var(--app-outline-radius) at 100% 100%, #0000 100%, #000);
  }

  &::after {
    /*! autoprefixer: off */

    @if $handle-root-scrollbar {
      right: var(--app-outline-width-right);
    } @else {
      right: var(--app-outline-width);
    }

    mask: radial-gradient(var(--app-outline-radius) at 0 100%, #0000 100%, #000);
    -webkit-mask: radial-gradient(var(--app-outline-radius) at 0 100%, #0000 100%, #000);
  }
}

/**
 * Bottom left & right rounded corners
 *
 * @param bool $handle-root-scrollbar  Whether to withdraw the root scrollbar width from the right corner position
 * @param css-unit $bottom-position  The position of the corners from the bottom of the screen
 */
@mixin bottom-corners($handle-root-scrollbar: true, $bottom-position: var(--app-outline-width)) {
  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    bottom: $bottom-position;
    width: var(--app-outline-radius);
    height: var(--app-outline-radius);
    z-index: 100;
    background-color: var(--app-outline-color);
  }

  &::before {
    /*! autoprefixer: off */

    left: var(--app-outline-width);
    mask: radial-gradient(var(--app-outline-radius) at 100% 0, #0000 100%, #000);
    -webkit-mask: radial-gradient(var(--app-outline-radius) at 100% 0, #0000 100%, #000);
  }

  &::after {
    /*! autoprefixer: off */

    @if $handle-root-scrollbar {
      right: var(--app-outline-width-right);
    } @else {
      right: var(--app-outline-width);
    }

    mask: radial-gradient(var(--app-outline-radius) at 0 0, #0000 100%, #000);
    -webkit-mask: radial-gradient(var(--app-outline-radius) at 0 0, #0000 100%, #000);
  }
}
