.calendar {
  display: flex;
  flex-direction: column;

  .calendar {
    &-header {
      display: flex;
      align-items: center;
      gap: 1em;
      margin: 0.5em;

      h3 {
        flex-grow: 1;
        margin: 0;
        text-align: center;
      }

      .button {
        .icon {
          display: inline-block;
          vertical-align: bottom;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: center;
    }

    td {
      padding: 0;

      &[aria-label] {
        position: relative;
        z-index: 1;

        &::after {
          display: none;
          content: attr(aria-label);
          position: absolute;
          top: -0.25em;
          left: 50%;
          padding: 0.5em 1.25em;
          background-color: color('white');
          border: 1px solid var(--current-color);
          border-radius: 5em;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          transform: translate(-50%, -100%);

          @media (prefers-reduced-motion: no-preference) {
            animation: appear 0.25s ease-in 0s alternate 1 backwards;
          }
        }

        &:hover::after {
          display: inline-block;
        }
      }
    }

    .cell {
      position: relative;
      cursor: default;
      text-align: center;
      transition: border-radius 0.25s ease-out;

      &:focus,
      &:focus-visible {
        outline: 1px solid var(--current-color);
        outline-offset: -0.2em;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: var(--current-color);
        opacity: 0;
        transition: opacity 0.25s ease-out;
      }

      &.is-selected {
        color: color('white');
        overflow: hidden;

        &::after {
          opacity: 0.75;
        }

        &:focus,
        &:focus-visible {
          outline-color: color('white');
        }
      }

      &.is-selection-start,
      &.is-selection-end {
        &::after {
          opacity: 1;
        }
      }

      &.l-rounded-top-left {
        border-top-left-radius: 1em;
      }

      &.l-rounded-top-right {
        border-top-right-radius: 1em;
      }

      &.l-rounded-bottom-right {
        border-bottom-right-radius: 1em;
      }

      &.l-rounded-bottom-left {
        border-bottom-left-radius: 1em;
      }

      &.is-unavailable {
        color: color('raspberry-light');
      }

      &.is-disabled {
        &:not(.is-selected) {
          opacity: 0.4;
        }

        &.is-selected {
          opacity: 0.8;
        }
      }
    }
  }

  [class*='bg-'] & {
    table {
      .cell {
        position: relative;
        z-index: 1;

        &:focus,
        &:focus-visible {
          outline-color: color('white');
        }

        &::after {
          background-color: color('white');
        }

        &.is-selected {
          &::after {
            opacity: 0.3;
          }
        }

        &.is-selection-start,
        &.is-selection-end {
          &::after {
            opacity: 0.5;
          }
        }
      }
    }
  }

  @each $color in $color-names-no-white {
    .bg-#{$color} & {
      table td[aria-label]::after {
        background-color: color('#{$color}');
        border-color: color('white');
        color: color('white');
      }
    }
  }
}
