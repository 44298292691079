.pagination {
  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
  }

  [class*='bg-'] &:not([class*='c-']),
  [class*='bg-'] &-button {
    --current-color: color('white');
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* stylelint-disable-next-line declaration-no-important -- Override .template ul */
    padding: 0 !important;
    margin: 0;
    list-style: none;
  }

  li {
    position: relative;

    &::before {
      /* stylelint-disable-next-line declaration-no-important -- Override .template li */
      display: none !important;
    }
  }

  &-button {
    padding-top: 0.25em;
    margin: 0 1em;
    color: var(--current-color);
    font-size: 1.5em;
  }

  &-filter {
    position: relative;

    .filter-button {
      padding: 0.5em 1.5em;
      border: 1px solid currentcolor;
      border-radius: 5em;
      font-size: 1em;
      font-weight: $font-weight-normal;
      text-transform: none;
      transform: none;
    }

    .menu {
      width: 100%;
      color: var(--current-color);
      text-shadow: none;
    }
  }

  &-current {
    display: inline-block;
    margin-top: -0.15em;
    font-family: $font-family-italic;
    font-style: italic;
  }
}
