.section {
  position: relative;
  padding: calc(var(--app-outline-width) * 1.5) 0;

  &-header {
    margin-bottom: calc(var(--app-outline-width) * 3);

    &[class*='bg-'] {
      padding: calc(var(--app-outline-width) * 2.5) calc(var(--app-outline-width) * 2);
      border-radius: var(--app-outline-radius);
    }

    .section-title {
      margin: 0;
    }
  }

  &-title {
    font-family: $font-family-serif;
    font-size: 2.5714em;
    font-weight: $font-weight-normal;
    text-align: center;
    text-transform: none;
  }

  &-footer {
    padding-top: calc(var(--app-outline-width) * 3);
  }

  &.l-narrow {
    padding-left: var(--app-outline-width);
    padding-right: var(--app-outline-width);

    @include media-breakpoint-up('lg') {
      padding-left: calc(var(--app-outline-width) * 2);
      padding-right: calc(var(--app-outline-width) * 2);
    }

    @include media-breakpoint-up('xl') {
      padding-left: calc(var(--app-outline-width) * 3);
      padding-right: calc(var(--app-outline-width) * 3);
    }

    @include media-breakpoint-up('xxl') {
      padding-left: calc(var(--app-outline-width) * 4);
      padding-right: calc(var(--app-outline-width) * 4);
    }
  }

  // &-loader {
  //   /** @see /styles/components/_loader.scss */
  // }

  // &.is-loading {
  //   min-height: calc(160px + 1.8651em + var(--app-outline-width) * 3);
  // }
}
