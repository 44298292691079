.section.partners {
  @include media-breakpoint-up('md') {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .section {
    &-header {
      margin-bottom: 0;

      @include media-breakpoint-up('md') {
        width: calc(33.3333% + var(--app-outline-width) * 2);
        min-width: calc(33.3333% + var(--app-outline-width) * 2);
      }

      @include media-breakpoint-up('xl') {
        width: calc(25% + var(--app-outline-width) * 1.5);
        min-width: calc(25% + var(--app-outline-width) * 1.5);
      }
    }

    &-title {
      font-size: 2.1905em;
    }

    &-content {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: calc(var(--app-outline-width) / 2 + 0.5em) 0 0 calc(var(--app-outline-width) / 2);

        @include media-breakpoint-up('md') {
          justify-content: flex-start;
        }

        li {
          &::before {
            display: none;
          }
        }
      }

      .image {
        margin: calc(var(--app-outline-width) / 1);
        text-align: center;

        @include media-breakpoint-up('md') {
          margin: calc(var(--app-outline-width) / 2);
        }

        img {
          width: auto;
          max-width: 6.6667em;
          height: auto;
          max-height: 6.6667em;

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.25s ease-out;
          }

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      .link {
        &::after {
          display: none;
        }
      }
    }
  }

  &:nth-child(even) {
    @include media-breakpoint-up('md') {
      justify-content: flex-end;

      .section {
        &-header {
          order: 2;
        }

        &-content {
          order: 1;

          ul {
            justify-content: flex-end;
            padding-right: calc(var(--app-outline-width) / 2);
            padding-left: 0;
          }
        }
      }
    }
  }
}
