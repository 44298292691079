.form {
  &-status,
  &-error,
  &-sent {
    margin-top: 0.5em;
    font-family: $font-family-italic;
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;

    @include media-breakpoint-up('xxs') {
      margin-left: calc(8em + var(--app-outline-width));
    }

    @include media-breakpoint-up('xs') {
      margin-left: calc(10em + var(--app-outline-width));
    }
  }

  &-status {
    color: color('carrot-light');
  }

  &-error {
    color: color('raspberry-light');
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
  }

  &-submit {
    display: flex;
    gap: var(--app-outline-width);
    margin-top: 0.5em;

    > div {
      text-align: right;

      &:first-child:not(:last-child) {
        width: 10em;
      }

      &:last-child {
        flex-grow: 1;
      }
    }
  }

  &-sent {
    color: color('nature');
  }
}
