.article.press,
.article.event,
.article.festival {
  position: relative;
  background-color: color('white');
  background-image: linear-gradient(
    115deg,
    rgba(color('charcoal', false), 0.025) 0%,
    color('white') 43%,
    color('white') 57%,
    rgba(color('charcoal', false), 0.025) 100%
  );
  border: 1px solid var(--current-color);
  border-radius: var(--app-outline-radius);
  overflow: hidden;

  > .link {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: var(--app-outline-width);
    text-decoration: none;

    @include media-breakpoint-up('md') {
      padding: calc(var(--app-outline-width) / 2);
    }

    .article-content {
      p {
        transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;
        text-decoration: underline solid rgba(color('primary', false), 0);
        text-underline-offset: 0.075em;
      }

      @media (prefers-reduced-motion: no-preference) {
        .icon-plus,
        .label {
          transition: transform 0.35s ease-out 0.35s;
        }
      }
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      .article-content {
        p {
          text-decoration: underline solid rgba(color('primary', false), 0.3);
          text-underline-offset: 0.15em;
        }

        .icon-plus {
          transform: rotate(-360deg);
        }

        .label {
          transform: translateX(0);
        }
      }
    }
  }

  .article {
    &-header {
      width: 4em;
      min-width: 4em;
      margin-bottom: 0;
      text-align: center;

      .image {
        img {
          display: block;
          width: auto;
        }
      }
    }

    &-title {
      font-size: 1.1579em;
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      min-height: 7.5em;
      padding-left: var(--app-outline-width);

      @include media-breakpoint-up('md') {
        padding-left: calc(var(--app-outline-width) / 2);
      }

      .icon-plus {
        display: inline-block;
        margin-right: var(--app-outline-width);

        @include media-breakpoint-up('md') {
          margin-right: calc(var(--app-outline-width) / 2);
        }
      }

      .label {
        align-self: flex-end;
        vertical-align: bottom;
        margin-bottom: -0.25em;
        font-family: $font-family-italic;
        font-size: 1.1429em;
        font-style: italic;
        font-weight: $font-weight-bold;
        transform: translateX(calc(100% - 1em));

        &::after {
          content: attr(data-label);
          display: inline-block;
          margin-left: 1px;
        }
      }

      @media (prefers-reduced-motion: no-preference) {
        .icon,
        .label {
          transition: transform 0.35s ease-out;
        }
      }
    }
  }
}

.article.press {
  .article {
    &-header {
      margin-top: 2em;

      .image {
        img {
          max-height: 2em;
        }
      }

      .date-time {
        display: block;
        margin-top: 0.5em;
        opacity: 0.5;
      }
    }

    &-title {
      //
    }

    &-content {
      p {
        flex-grow: 1;
      }
    }
  }
}
