.section.program {
  padding: 0;

  .section {
    &-content {
      padding: calc(var(--app-outline-width) / 2) var(--app-outline-width);
      border-radius: var(--app-outline-radius);

      .section-link {
        margin-top: calc(var(--app-outline-width) / 2);
        text-align: right;
      }

      /** Uncomment to put the text in the color of the outline */
      // &.l-outline.c {
      //   @each $color in $color-names-no-white {
      //     &-#{$color} {
      //       color: color('#{$color}');
      //     }
      //   }
      // }

      &.program-map {
        height: 100%;
        padding: 0;
      }
    }
  }

  p.no-result {
    padding: 0 calc(var(--app-outline-width) * 2);
    text-align: left;
  }
}
