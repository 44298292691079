/* stylelint-disable no-descending-specificity */
/* stylelint-disable value-keyword-case */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */

html {
  /**
   * Font size: ~17px for mobile, ~21px for desktop
   */
  font: calc(var(--font-size-base) + 0.2627vw) / $line-height-lg $font-family-sans;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

body {
  font-family: $font-family-sans;
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'onum';
     -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'onum';
          font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'onum';
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     -moz-font-smoothing: antialiased;
  font-weight: $font-weight-light;
  line-height: $line-height-lg;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

::-moz-selection {
  color: color('white');
  background-color: color('secondary');
}

::selection {
  color: color('white');
  background-color: color('secondary');
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 0.5em 0;
  color: inherit;
  font-family: $font-family-serif;
  font-weight: $font-weight-normal;
  line-height: $line-height-sm;

  strong {
    font-family: $font-family-italic;
    font-style: italic;
    font-size: 1.0139em;
    font-weight: $font-weight-bold;
  }
}

h2, .h2 {
  font-family: $font-family-sans;
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}
h1, .h1 { font-size: $h1-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }
.h7 { font-size: $h7-font-size; }

p, ul, address {
  margin: 0 0 0.75em 0;

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  padding-left: 1em;
}

p + ul {
  // margin-top: -0.75em;
}

strong {
  font-weight: $font-weight-medium;
}

a,
.icon,
.icon::before,
label {
  transition: color 0.25s ease-out;
}

a {
  color: currentcolor;
  font-weight: $font-weight-normal;
  text-decoration: underline solid rgba(color('primary', false), 0.3);
  text-underline-offset: 0.15em;
  transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within, {
    outline: none;
    color: currentcolor;
    text-decoration: underline solid rgba(color('primary', false), 0);
    text-underline-offset: 0.075em;
  }

  [class*='bg-'] & {
    text-decoration-color: rgba(color('white', false), 0.2);

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within, {
      text-decoration-color: rgba(color('white', false), 0);
    }
  }
}

u,
span[style*='underline'] {
  text-decoration: underline solid rgba(color('primary', false), 0.4);
  text-underline-offset: 0.15em;
}

em {
  font-family: $font-family-italic;
  font-size: 1.2381em;
  font-style: italic;
  line-height: 0.8077em;
}

address {
  font-style: normal;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.oe {
  letter-spacing: -0.2em;

  &::after {
    content: ' ';
    font-size: 1.5em;
    line-height: 0;
  }
}

.icon.icon-heart {
  font-size: 0.695em;
}
