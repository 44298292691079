html {
  --app-outline-width-right: calc(var(--app-outline-width) - var(--scrollbar-width));

  box-sizing: border-box;
  height: 100%;
  padding: 0 var(--app-outline-width-right) 0 var(--app-outline-width);
  background-color: var(--app-outline-color);

  @include scrollbar(color('secondary'));

  &[style] {
    /* stylelint-disable-next-line declaration-no-important -- Override react-aria Overlay inline styles */
    padding-right: var(--app-outline-width-right) !important;
  }

  /** Top & bottom borders */
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: var(--app-outline-width);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--app-outline-color);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  --current-color: var(--primary);

  min-height: 100%;
  padding: var(--app-outline-width) 0;
  background-color: var(--app-outline-color);
  color: color('primary');

  /** Top left & right rounded corners */
  @include top-corners;

  &.is-loading {
    overflow: hidden;
  }
}

#root {
  /** Bottom left & right rounded corners */
  @include bottom-corners;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input,
.input,
select,
textarea {
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: $font-family-sans-inputs;
  font-weight: $font-weight-normal;

  &:disabled,
  &.is-disabled {
    cursor: default;
    opacity: 0.5;
  }
}

input:not([type='radio']):not([type='checkbox']),
.input,
select,
textarea {
  padding: 0.25em 0.6666em;
  border: 1px solid color('charcoal-light');
  border-radius: var(--app-outline-radius);
  transition: border-color 0.25s ease-out;

  &:placeholder-shown::placeholder,
  .is-placeholder {
    color: inherit;
    opacity: 0.4;
  }

  &:focus,
  &:focus-within {
    border-color: color('charcoal-dark');
  }

  [class*='bg-'] & {
    border-color: #fff9;

    &:focus,
    &:focus-within {
      border-color: #fff;
    }
  }
}

textarea {
  padding: calc(var(--app-outline-width) / 2) 0.6666em;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

[hidden] {
  display: none;
}

[role='separator'] {
  display: inline-block;
  width: 5px;
  height: 1px;
  margin: 0 0.1em;
  background-color: color('primary');
}

/** Colors */
.c {
  @each $color in $color-names-no-white {
    &-#{$color} {
      --current-color: #{color('#{$color}')};
    }
  }
}

/** Backgrounds */
.bg {
  @each $color in $color-names-no-white {
    &-#{$color} {
      background-image: linear-gradient(
        115deg,
        color('#{$color}-dark') 0%,
        color('#{$color}') 43%,
        color('#{$color}') 57%,
        color('#{$color}-dark') 100%
      );
    }
  }

  &-honey,
  &-aqua,
  &-honey .button,
  &-aqua .button {
    text-shadow: 1px 1px 0 rgba(color('primary', false), 0.2);
  }
}

[class*='bg-']:not(.bg-white) {
  color: color('white');

  .template & ul li::before {
    color: color('white');
  }
}

.iframe {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: var(--app-outline-radius);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/** Outline layout */
.l-outline {
  background-image: linear-gradient(
    115deg,
    rgba(color('charcoal', false), 0.025) 0%,
    color('white') 43%,
    color('white') 57%,
    rgba(color('charcoal', false), 0.025) 100%
  );
  border: 1px solid var(--current-color);

  @each $color in $color-names-no-white {
    &.bg-#{$color} {
      --current-color: #{color('#{$color}')};

      color: color('#{$color}');
    }
  }
}

/** Hide reCAPTCHA BADGE */
.grecaptcha-badge {
  /* stylelint-disable-next-line declaration-no-important -- Override inline styles */
  display: none !important;
}
