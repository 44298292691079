.section.press,
.section.archives {
  padding-left: var(--app-outline-width);
  padding-right: var(--app-outline-width);

  @include media-breakpoint-up('lg') {
    padding-left: calc(var(--app-outline-width) * 2);
    padding-right: calc(var(--app-outline-width) * 2);
  }

  .section {
    &-content {
      display: grid;
      gap: var(--app-outline-width);

      @include media-breakpoint-up('sm') {
        --width: calc(50% - var(--app-outline-width) / 2);

        grid-template-columns: var(--width) var(--width);
      }

      @include media-breakpoint-up('md') {
        --width: calc(33.3333% - var(--app-outline-width) * 2 / 3);

        grid-template-columns: var(--width) var(--width) var(--width);
      }

      .article {
        margin-bottom: 0;
      }
    }
  }
}
