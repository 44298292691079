.article.sponsor {
  clear: both;

  @include media-breakpoint-up('md') {
    display: flex;
  }

  .article {
    &-content {
      padding: 0 var(--app-outline-width);

      @include media-breakpoint-up('md') {
        width: 50%;
      }

      @include media-breakpoint-up('lg') {
        padding: 0 calc(var(--app-outline-width) * 1.5);
      }
    }

    &-images {
      width: calc(33.3333% + var(--app-outline-width) * 3);
      padding: 0 var(--app-outline-width) calc(var(--app-outline-width) * 2) var(--app-outline-width);

      @include media-breakpoint-up('md') {
        width: 25%;
        padding-bottom: 0;
      }
    }
  }

  &:nth-child(odd) {
    .article {
      &-content {
        //
      }

      &-images {
        padding-right: calc(var(--app-outline-width) * 2);
        float: left;

        @include media-breakpoint-up('md') {
          padding-right: var(--app-outline-width);
          padding-left: 0;
          float: none;
        }

        @include media-breakpoint-up('lg') {
          padding-right: calc(var(--app-outline-width) * 1.5);
        }
      }
    }
  }

  &:nth-child(even) {
    .article {
      &-content {
        @include media-breakpoint-up('md') {
          order: 1;
          margin-left: 25%;
        }
      }

      &-images {
        padding-left: calc(var(--app-outline-width) * 2);
        float: right;

        @include media-breakpoint-up('md') {
          order: 2;
          padding-right: 0;
          padding-left: var(--app-outline-width);
          float: none;
        }

        @include media-breakpoint-up('lg') {
          padding-left: calc(var(--app-outline-width) * 1.5);
        }
      }
    }
  }
}
