.menu {
  position: absolute;
  z-index: 1;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  &.l-dropdown {
    padding: calc(var(--app-outline-width) / 1.5) var(--app-outline-width);
    margin-left: calc(var(--app-outline-width) / -1);
    background-color: color('white');
    border: 1px solid rgba(color('primary', false), 0.2);
    border-radius: var(--app-outline-radius);

    @media (prefers-reduced-motion: no-preference) {
      animation: appear 0.25s ease-in 0s alternate 1 backwards;
      transition: opacity 0.25s ease-in;

      &.is-closing {
        opacity: 0;
      }
    }
  }

  &.p-top {
    left: calc(50% + var(--app-outline-width));
    transform: translate(-50%, -100%);
  }

  &[class*='p-top'] {
    top: 0;
  }

  &[class*='p-bottom'] {
    top: 100%;
  }

  &.p-top-left {
    left: 0;
    transform: translateY(-100%);
  }

  &.p-right,
  &.p-bottom-right {
    right: 0;

    &.l-dropdown {
      margin: 0 calc(var(--app-outline-width) / -1) 0 0;
    }
  }

  .close-button {
    position: absolute;
    z-index: 2;
    top: calc(var(--app-outline-width) / 2);
    right: calc(var(--app-outline-width) / 2);
    padding: 0.3333em;
    color: currentcolor;
    font-size: 0.75em;
    transition: opacity 0.25s ease-out;

    @include media-breakpoint-up('md') {
      padding: calc(var(--app-outline-width) / 2 - 0.25em);
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      opacity: 0.5;
    }
  }
}
