/**
 * Make a template banner sticky
 */
@mixin sticky-banner {
  position: sticky;
  z-index: 10;
  top: var(--app-outline-width);
  background-color: var(--app-outline-color);

  /** Top left & right rounded corners over the content */
  @include top-corners(true, 100%);

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
