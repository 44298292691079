.image-gallery {
  & + .lg-backdrop {
    background-color: var(--app-outline-color);
  }

  & ~ .lg-outer {
    .lg-inner {
      .lg-item {
        --header-height: 2.5em;

        width: calc(100% - var(--app-outline-width) * 2);
        height: calc(100% - var(--app-outline-width) - var(--header-height));
        margin: var(--header-height) var(--app-outline-width) var(--app-outline-width) var(--app-outline-width);
        border-radius: var(--app-outline-radius);
        overflow: hidden;
      }

      .lg-image {
        border-radius: var(--app-outline-radius);
      }
    }

    .lg-toolbar {
      padding: 0 var(--app-outline-width);
      background-color: var(--app-outline-color);

      .lg-icon {
        color: color('charcoal');
        transition: color 0.25s ease-out;

        &:hover {
          color: color('charcoal-light');
        }
      }

      #lg-counter {
        color: color('charcoal-light');
        font-family: $font-family-italic;
        font-feature-settings: 'lnum';
        font-size: 1.0139em;
        font-style: italic;
        font-weight: $font-weight-normal;
        line-height: $line-height-base;

        span {
          font-weight: $font-weight-bold;

          &#lg-counter-current {
            color: color('charcoal');
          }
        }
      }

      #lg-download {
        @include visually-hidden;
      }
    }

    .lg-actions {
      .lg-prev,
      .lg-next {
        width: 2.5em;
        height: 2.5em;
        background-color: color('white');
        border: 1px solid var('secondary');
        border-radius: 100%;
        color: color('charcoal');
        font-size: 1.2rem;
        text-align: center;
      }

      .lg-prev {
        left: -1.25em;
        transform: rotate(90deg);
      }

      .lg-next {
        right: -1.25em;
        transform: rotate(-90deg);
      }

      .lg-prev::after,
      .lg-next::before {
        @include icon('\e90b', 1rem, color('charcoal'));

        display: inline-block;
        vertical-align: middle;
        transform: translateY(-0.85em);
        transition: color 0.25s ease-out;
      }

      .lg-prev:hover::after,
      .lg-next:hover::before {
        color: color('charcoal-light');
      }
    }
  }
}

body.lg-on {
  overflow: hidden;
}
