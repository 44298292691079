button:focus-visible,
[type='button']:focus-visible,
[type='reset']:focus-visible,
[type='submit']:focus-visible {
  outline: none;
}

.button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  font: inherit;
  line-height: $line-height-base;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;

  &:disabled,
  &.is-disabled {
    cursor: default;
    opacity: 0.75;
  }

  &.l-outline,
  &.l-plain {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.5em 1.5em;
    border: 1px solid currentcolor;
    border-radius: 5em;
    font-family: $font-family-italic;
    text-shadow: none;
    font-size: 1.2381em;
    font-style: italic;
    font-weight: $font-weight-bold;
    text-decoration: none;
    white-space: nowrap;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      border: 1px solid currentcolor;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 5em;

      @media (prefers-reduced-motion: no-preference) {
        transition: clip-path 0.35s ease-out;
      }
    }

    &::before {
      clip-path: polygon(0 0, 1.125em 0, 1.125em 1.125em, 0 1.125em);
    }

    &::after {
      clip-path: polygon(
        calc(100% - 1.125em) calc(100% - 1.125em),
        100% calc(100% - 1.125em),
        100% 101%,
        calc(100% - 1.125em) 101%
      );
    }

    &:not(.is-disabled):not(:disabled):hover,
    &:not(.is-disabled):not(:disabled):focus,
    &:not(.is-disabled):not(:disabled):focus-visible,
    &:not(.is-disabled):not(:disabled):focus-within,
    .button:not(.is-disabled):not(:disabled):hover &,
    .button:not(.is-disabled):not(:disabled):focus &,
    .button:not(.is-disabled):not(:disabled):focus-visible &,
    .button:not(.is-disabled):not(:disabled):focus-within & {
      &::before {
        clip-path: polygon(calc(100% - 1.125em) 0, 100% 0, 100% 1.25em, calc(100% - 1.125em) 1.25em);
      }

      &::after {
        clip-path: polygon(0 calc(100% - 1.125em), 1.25em calc(100% - 1.125em), 1.25em 101%, 0 101%);
      }
    }
  }

  &.l-outline {
    @each $color in $color-names {
      &.c-#{$color} {
        color: color('#{$color}');
      }
    }

    /** Gradient effect on rollover */
    @include media-breakpoint-up('sm') {
      @media (prefers-reduced-motion: no-preference) {
        &[class*='c-'] {
          /* stylelint-disable-next-line property-no-vendor-prefix */
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          background-size: 200%;
          background-position-x: 100%;
          transition: background-position-x 0.7s ease-out;

          &:not(:disabled):not(.is-disabled) {
            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              background-position-x: -100%;
            }
          }
        }

        /** This makes the sass builder fail for production builds only :-( */
        // @each $color in $color-names {
        //   &.c-#{$color} {
        //     background-image: linear-gradient(
        //       to right,
        //       color('#{$color}') 0%,
        //       rgba(color('#{$color}', false), 0.6666) 16.6667%,
        //       rgba(color('#{$color}', false), 0.6666) 33.3333%,
        //       color('#{$color}') 50%
        //     );
        //   }
        // }

        &.c-charcoal {
          background-image: linear-gradient(
            to right,
            color('charcoal') 0%,
            rgba(color('charcoal', false), 0.6666) 16.6667%,
            rgba(color('charcoal', false), 0.6666) 33.3333%,
            color('charcoal') 50%
          );
        }

        &.c-nature {
          background-image: linear-gradient(
            to right,
            color('nature') 0%,
            rgba(color('nature', false), 0.6666) 16.6667%,
            rgba(color('nature', false), 0.6666) 33.3333%,
            color('nature') 50%
          );
        }

        &.c-honey {
          background-image: linear-gradient(
            to right,
            color('honey') 0%,
            rgba(color('honey', false), 0.6666) 16.6667%,
            rgba(color('honey', false), 0.6666) 33.3333%,
            color('honey') 50%
          );
        }

        &.c-carrot {
          background-image: linear-gradient(
            to right,
            color('carrot') 0%,
            rgba(color('carrot', false), 0.6666) 16.6667%,
            rgba(color('carrot', false), 0.6666) 33.3333%,
            color('carrot') 50%
          );
        }

        &.c-raspberry {
          background-image: linear-gradient(
            to right,
            color('raspberry') 0%,
            rgba(color('raspberry', false), 0.6666) 16.6667%,
            rgba(color('raspberry', false), 0.6666) 33.3333%,
            color('raspberry') 50%
          );
        }

        &.c-iris {
          background-image: linear-gradient(
            to right,
            color('iris') 0%,
            rgba(color('iris', false), 0.6666) 16.6667%,
            rgba(color('iris', false), 0.6666) 33.3333%,
            color('iris') 50%
          );
        }

        &.c-cornflower {
          background-image: linear-gradient(
            to right,
            color('cornflower') 0%,
            rgba(color('cornflower', false), 0.6666) 16.6667%,
            rgba(color('cornflower', false), 0.6666) 33.3333%,
            color('cornflower') 50%
          );
        }

        &.c-aqua {
          background-image: linear-gradient(
            to right,
            color('aqua') 0%,
            rgba(color('aqua', false), 0.6666) 16.6667%,
            rgba(color('aqua', false), 0.6666) 33.3333%,
            color('aqua') 50%
          );
        }

        &.c-wood {
          background-image: linear-gradient(
            to right,
            color('wood') 0%,
            rgba(color('wood', false), 0.6666) 16.6667%,
            rgba(color('wood', false), 0.6666) 33.3333%,
            color('wood') 50%
          );
        }

        &.c-peanut {
          background-image: linear-gradient(
            to right,
            color('peanut') 0%,
            rgba(color('peanut', false), 0.6666) 16.6667%,
            rgba(color('peanut', false), 0.6666) 33.3333%,
            color('peanut') 50%
          );
        }

        &.c-white {
          background-image: linear-gradient(
            to right,
            color('white') 0%,
            rgba(color('white', false), 0.6666) 16.6667%,
            rgba(color('white', false), 0.6666) 33.3333%,
            color('white') 50%
          );
        }
      }
    }
  }

  &.l-plain {
    background-color: color('primary');
    color: color('white');

    @each $color in $color-names {
      &.c-#{$color} {
        background-color: color('#{$color}');
        border-color: color('#{$color}');
      }
    }
  }

  &.l-arrow {
    &:disabled {
      opacity: 0.5;
      text-shadow: none;
    }

    &:not(:disabled) {
      transition: transform 0.35s ease-out;

      &.l-prev {
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          transform: translateX(-25%);
        }
      }

      &.l-next {
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          transform: translateX(25%);
        }
      }
    }
  }
}
