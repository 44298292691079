.date-time {
  font-weight: $font-weight-medium;
}

.date-time-range {
  [role='separator'] {
    @include visually-hidden;
  }

  .dtr {
    &-dates {
      white-space: nowrap;
    }

    &-times {
      font-size: 0.9048em;
      white-space: nowrap;

      &::before {
        @include icon('\1f553', 0.7895em, currentcolor);

        display: inline-block;
        vertical-align: middle;
        margin-right: 0.3333em;
        transform: translateY(-0.02em);
      }

      .date-time {
        font-weight: $font-weight-light;
      }
    }
  }

  .icon[class*='chevron-right'] {
    vertical-align: middle;
  }
}
