/**
 * Display an icomoon icon
 */
@mixin icon($char, $size: 18px, $color: #000) {
  content: $char;
  color: $color;
  font-family: 'icomoon' !important; // stylelint-disable-line
  font-size: $size;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
