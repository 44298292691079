.article {
  margin-bottom: calc(var(--app-outline-width) * 2);

  &:last-child {
    margin-bottom: 0;
  }

  &-header {
    margin-bottom: calc(var(--app-outline-width) * 1.5);

    &[class*='bg-'] {
      padding: var(--app-outline-width);
      border-radius: var(--app-outline-radius);
    }

    .article-title {
      margin: 0;
    }
  }

  &-title {
    font-family: $font-family-sans;
    font-size: 1.3333em;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }

  &-content {
    font-size: 0.9048em;

    .article-title {
      font-size: 1.4737em;
    }
  }

  &-images {
    .image {
      border-radius: var(--app-outline-radius);
      overflow: hidden;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
