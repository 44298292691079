.section.sponsors {
  .section {
    &-content {
      padding-left: var(--app-outline-width);
      padding-right: var(--app-outline-width);

      @include media-breakpoint-up('lg') {
        padding-left: calc(var(--app-outline-width) * 2);
        padding-right: calc(var(--app-outline-width) * 2);
      }
    }
  }
}
