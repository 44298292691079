/**
 * Whether to use css or sass variables
 * Responsive variables only works with $css-vars: true
 */
$css-vars: true !default;

 /**
  * Colors
  */
$colors: (
  --primary:          #4f4f4f,
  --secondary:        #64ac4f,
  --charcoal:         #4f4f4f,
  --charcoal-dark:    #434343,
  --charcoal-light:   #7b7b7b,
  --nature:           #64ac4f,
  --nature-dark:      #559243,
  --nature-light:     #bbc17b,
  --honey:            #edbb1d,
  --honey-dark:       #d5a81a,
  --honey-light:      #f2cc56,
  --carrot:           #f2821d,
  --carrot-dark:      #d9751a,
  --carrot-light:     #f5a156,
  --raspberry:        #e01038,
  --raspberry-dark:   #c90e32,
  --raspberry-light:  #e84c6a,
  --iris:             #9467bf,
  --iris-dark:        #855cac,
  --iris-light:       #af8dcf,
  --cornflower:       #3381c1,
  --cornflower-dark:  #2e74ad,
  --cornflower-light: #66a1d1,
  --aqua:             #51bcb2,
  --aqua-dark:        #49a9a0,
  --aqua-light:       #7dcdc5,
  --wood:             #726149,
  --wood-dark:        #665742,
  --wood-light:       #958977,
  --peanut:           #bca973,
  --peanut-dark:      #a99867,
  --peanut-light:     #cdbf96,
  --black:            #000,
  --white:            #fff,
);
@if $css-vars {
  :root {
    @each $color, $value in $colors {
      #{$color}: #{$value};
    }
  }
}

$color-names: charcoal nature honey carrot raspberry iris cornflower aqua wood peanut white;
$color-names-no-white: charcoal nature honey carrot raspberry iris cornflower aqua wood peanut;

/**
 * Breakpoints
 */
$breakpoints: (
  width-xxs:    460px,
  width-xs:     576px,
  width-sm:     768px,
  width-md:     992px,
  width-lg:    1200px,
  width-xl:    1560px,
  width-xxl:   1900px,
  width-xxxl:  2260px,
  width-huge:  2520px,
  height-xxs:   320px,
  height-xs:    420px,
  height-sm:    540px,
  height-md:    660px,
  height-lg:    800px,
  height-xl:    940px,
  height-xxl:  1080px,
  height-xxxl: 1220px,
  height-huge: 1360px
);

/**
 * Fonts
 */
$font-family-sans: terfens, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-sans-inputs: terfens-new, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-serif: expo-serif-pro, 'Times New Roman', Times, serif;
$font-family-italic: expo-sans-pro, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs:  ($font-size-base / 1.5);
$font-size-sm:  ($font-size-base / 1.25);
$font-size-lg:  ($font-size-base * 1.25);
$font-size-xl:  ($font-size-base * 1.5);
$font-weight-thin:      100; // Available for
$font-weight-xlight:    200; // Available for
$font-weight-light:     300; // Available for terfens, expo-sans-pro
$font-weight-normal:    400; // Available for terfens, terfens-new, expo-sans-pro, expo-serif-pro
$font-weight-medium:    500; // Available for terfens
$font-weight-semibold:  600; // Available for expo-serif-pro
$font-weight-bold:      700; // Available for terfens, expo-sans-pro, expo-serif-pro
$font-weight-extrabold: 800; // Available for
$font-weight-black:     900; // Available for
$font-weight-ultra:    1000; // Available for
$line-height-base: 1.2924;
$line-height-sm:   1.1087;
$line-height-lg:   1.476;

/**
 * Headings
 */
$h1-font-size: 2em;
$h2-font-size: 1.4286em;
$h3-font-size: 2.5714em;
$h4-font-size: 2.1905em;
$h5-font-size: 1.8095em;
$h6-font-size: 1.4286em;
$h7-font-size: 1.1905em;

/**
 * Custom variables
 */
$variables: (
  /** Scrollbar width, set in javascript -- The unit must be set in order to be usable in calc() */
  --scrollbar-width: 0px,
  /** App outline properties */
  --app-outline-width: 2.0833vw,
  --app-outline-radius: calc(2.0833vw * 1.25),
  --app-outline-color: color('white'),
  /** Header properties */
  --header-height: 2.5em,
  /** Container properties */
  --container-padding-h: 2vw,
  --container-padding-v: 2em,
  --container-max-width: 2880px,
  /** Font properties */
  --font-size-base: $font-size-base,
);
@if $css-vars {
  :root {
    @each $variable, $value in $variables {
      #{$variable}: #{$value};
    }
  }
}

/**
 * Responsive variables
 */
@if $css-vars {
  @include media-breakpoint-up('sm') {
    $variables: (
      --header-height: 3.8095em,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @media (min-aspect-ratio: 2.25) {
    $variables: (
      --app-outline-width: calc(min(2.3333em, 2.0833vw)),
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }
}
