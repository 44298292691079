.loader {
  /**
   * For a quicker rendering, the base loader styles are defined in /public/index.html
   */

  @each $color in $color-names-no-white {
    &.c-#{$color}::before {
      background-image: linear-gradient(
        115deg,
        color('#{$color}-dark') -5%,
        color('#{$color}') 50%,
        color('#{$color}-dark') 105%
      );
    }
  }

  &.section-loader {
    position: absolute;

    &::before {
      clip-path: none;
      border-radius: var(--app-outline-radius);
    }

    > div {
      margin: 0 auto;
    }
  }
}
