.table.events {
  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
  }

  thead {
    /** Comment this to show labels */
    @include visually-hidden;
  }

  tbody {
    tr {
      &.is-draft {
        opacity: 0.5;
      }
    }

    @include media-breakpoint-down('md') {
      td {
        &:nth-child(-n + 2) {
          width: 50%;
          padding-top: 0;
        }

        &:nth-child(1) {
          padding-right: calc(var(--app-outline-width) / 2);
        }

        &:nth-child(2) {
          padding-left: calc(var(--app-outline-width) / 2);
        }

        &::before {
          /** Comment this to show labels for mobile */
          display: none;
        }
      }
    }
  }

  &:not(.is-disabled) {
    tbody tr {
      cursor: pointer;
    }
  }

  [role='separator'] {
    @include visually-hidden;
  }

  .link {
    text-decoration: none;
  }

  span[class^='td-'],
  .date-time-range [class^='dtr-'] {
    display: block;
  }

  .date-time-range {
    .dtr-times {
      padding-top: 0.125rem;
    }
  }

  span.td {
    &-festival {
      font-weight: $font-weight-medium;
    }

    &-city {
      padding-top: 0.15rem;
      font-size: 0.9em;

      @include media-breakpoint-up('xxs') {
        white-space: nowrap;
      }

      &::before {
        @include icon('\1f4cc', 0.714rem, currentcolor);

        display: inline-block;
        vertical-align: middle;
        margin-right: 0.25em;
        transform: translateY(-0.125em);
      }
    }

    &-event {
      padding-top: 0.075rem;
      font-size: 1.4em;
      font-weight: $font-weight-bold;
      line-height: calc(#{$line-height-sm} * 1.1rem);
      text-transform: uppercase;

      .link {
        font-weight: $font-weight-bold;
      }
    }

    &-thematic {
      margin-top: -0.05rem;
      font-family: $font-family-italic;
      font-size: 0.8em;
      font-style: italic;
    }

    &-category {
      font-size: 0.8em;
      font-weight: $font-weight-medium;
    }

    &-promoter {
      padding-top: 0.4rem;
      font-family: $font-family-italic;
      font-size: 0.8em;
      font-style: italic;
      line-height: calc(#{$line-height-sm} * 0.7rem);

      &::before {
        @include icon('\1f9cd', 0.714rem, currentcolor);

        display: inline-block;
        vertical-align: middle;
        margin-right: 0.25em;
        transform: translateY(-0.225em);
      }
    }
  }
}
