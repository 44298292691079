.cookie-notice {
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 10;

  h6 {
    padding-right: 1em;
    white-space: nowrap;
  }

  p {
    font-size: 0.8571em;
  }

  .button {
    display: flex;
    align-items: center;
    padding: 0.25em;
    text-align: left;
    line-height: $line-height-sm;

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      .cookie path {
        fill: color('charcoal-dark');
      }
    }

    .label {
      padding-right: 1em;
      opacity: 0.5;
      font-family: $font-family-italic;
      font-size: 0.7619em;
      font-style: italic;
    }
  }

  .menu {
    min-width: 10em;

    &.p-top {
      top: -0.5em;
    }
  }

  .cookie {
    height: 1.5em;

    path {
      fill: color('charcoal-light');
      transition: fill 0.25s ease-out;
    }
  }
}
