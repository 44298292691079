.map {
  width: 100%;
  height: 100%;
  border-radius: var(--app-outline-radius);
  overflow: hidden;

  &-wrapper {
    width: 100%;
    height: 100%;
    color: var(--current-color);
  }

  &-marker {
    &-iw.iw {
      min-height: 120px;

      .loader.section-loader {
        > div {
          transform: scale(0.5);
        }

        &::before {
          border-radius: 0;
        }
      }

      .iw-content {
        display: flex;
      }

      .iw-link {
        margin-top: 0.5em;
        font-size: 0.9048em;

        &.has-image {
          padding-left: calc(120px + 0.5em);
        }
      }

      .image {
        padding-right: 0.5em;

        img {
          max-width: 120px;
          border-radius: 0.5em;
        }
      }

      p,
      address {
        margin-bottom: 0;
        font-family: $font-family-sans;
        font-size: 0.7143rem;
        color: color('charcoal');
      }
    }
  }

  &-cookie-notice,
  &-load-error {
    padding: calc(var(--app-outline-width) * 2.5) var(--app-outline-width);
    text-align: center;

    @include media-breakpoint-down('sm') {
      padding-bottom: calc(var(--header-height) * 2 + var(--app-outline-width));
    }

    @media (prefers-reduced-motion: no-preference) {
      animation: appear 0.25s ease-in 0s alternate 1 backwards;
    }

    .link::after {
      /* stylelint-disable-next-line declaration-no-important -- Override .template a[target="_blank"]:not(.button):not(.video-link)::after from _template.scss */
      color: color('white') !important;
    }

    .hr {
      margin-bottom: 1em;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up('xs') {
        flex-direction: row;
      }

      .button {
        width: 10em;

        &:first-child {
          opacity: 0.7;
          transition: opacity 0.25s ease-out, background-position-x 0.7s ease-out;

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            opacity: 1;
          }

          @include media-breakpoint-down('xs') {
            order: 2;
          }

          @include media-breakpoint-up('xs') {
            margin-right: var(--app-outline-width);
          }
        }

        &:last-child {
          @include media-breakpoint-down('xs') {
            order: 1;
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }

  /** Update the style of the Info Window */
  .gm-style-iw {
    padding: 0.5em;
    box-shadow: none;
    outline: 1px solid color('white');
    border: 3px solid var(--current-color);
  }

  /** Update the style of the Info Window arrow */
  .gm-style-iw-tc {
    top: 0;
    width: 23px;
    height: 15px;
    background-color: color('white');
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    filter: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 2px;
      z-index: -1;
      width: 19px;
      height: 14px;
      background-color: var(--current-color);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }

    &::after {
      width: 15px;
      left: 4px;
      display: none;
    }
  }

  /** Set a border radius to the map zoom controls */
  .gmnoprint.gm-bundled-control > .gmnoprint > div {
    /* stylelint-disable-next-line declaration-no-important -- Override Google inline styles */
    border-radius: 1em !important;
  }

  .gm-style > div:last-child > div[style] {
    /** Add a border radius to the top left corner of the first element of the legal data */
    > .gmnoprint:first-child > .gm-style-cc:first-child {
      > div:first-child > div:last-child {
        border-top-left-radius: 0.5em;
      }
    }

    /** Add a padding to the last element of the legal data so it's not cropped by the app border radius */
    > .gm-style-cc:last-child {
      padding-right: var(--app-outline-width);
    }
  }
}
