/**
 * node_modules imports
 */
@import '~normalize.css';

/**
 * Mixins
 */
@import 'styles/mixins/breakpoints';
@import 'styles/mixins/css-vars';
@import 'styles/mixins/icon';
@import 'styles/mixins/scrollbar';
@import 'styles/mixins/corners';
@import 'styles/mixins/sticky-banner';

/**
 * Variables
 */
@import 'styles/variables';

/**
 * Globals
 */
@import 'styles/a11y';
@import 'styles/global';
@import 'styles/typography';
@import 'styles/animations';
@import 'styles/wp-classes';

/**
 * Components
 */
@import 'styles/components/icon';
@import 'styles/components/loader';
@import 'styles/components/image';
@import 'styles/components/image-gallery';
// @import 'styles/components/container';
@import 'styles/components/button';
@import 'styles/components/link';
@import 'styles/components/menu';
@import 'styles/components/headline';
@import 'styles/components/festivals-map';
@import 'styles/components/count-up';
@import 'styles/components/date-time';
@import 'styles/components/calendar';
@import 'styles/components/collapsable';
@import 'styles/components/modal';
@import 'styles/components/cookie-notice';
@import 'styles/components/hr';
@import 'styles/components/map';
@import 'styles/components/forms/form';
@import 'styles/components/forms/contact-form';
@import 'styles/components/forms/fields/field';
@import 'styles/components/forms/fields/date-field';
@import 'styles/components/forms/fields/radio-group';
@import 'styles/components/forms/fields/checkbox-group';
@import 'styles/components/forms/fields/switch';
@import 'styles/components/filters/filter';
@import 'styles/components/filters/date-filter';
@import 'styles/components/filters/festivals-filter';
@import 'styles/components/filters/search';
@import 'styles/components/pagination'; // Should be after filters/filter as it extends it with pagination-filter
@import 'styles/components/images/cookie';
@import 'styles/components/images/beegreen';

/**
 * Layouts
 */
@import 'styles/components/layouts/header';
@import 'styles/components/layouts/footer';
@import 'styles/components/layouts/template';
@import 'styles/components/layouts/hero';
@import 'styles/components/layouts/section';
@import 'styles/components/layouts/article';
@import 'styles/components/layouts/table';
@import 'styles/components/layouts/no-result';
@import 'styles/components/layouts/sections/featured';
@import 'styles/components/layouts/sections/flexible';
@import 'styles/components/layouts/sections/sponsors';
@import 'styles/components/layouts/sections/partners';
@import 'styles/components/layouts/sections/program';
@import 'styles/components/layouts/sections/press';
@import 'styles/components/layouts/sections/contact';
@import 'styles/components/layouts/sections/create-festival';
@import 'styles/components/layouts/sections/create-event';
@import 'styles/components/layouts/articles/sponsor';
@import 'styles/components/layouts/articles/press';
@import 'styles/components/layouts/articles/event';
@import 'styles/components/layouts/articles/festival';
@import 'styles/components/layouts/tables/events';

/**
 * Templates
 */
@import 'styles/components/templates/front-page';
@import 'styles/components/templates/program';
@import 'styles/components/templates/press';
@import 'styles/components/templates/single-festival';
@import 'styles/components/templates/single-event';
@import 'styles/components/templates/create-event';
@import 'styles/components/templates/my-events';
@import 'styles/components/templates/archives';
@import 'styles/components/templates/page-404';

/**
 * TODO: remove this to restore language selectors
 */
.header .nav-language {
  display: none;
}
