.featured-wrap {
  display: flex;
  flex-wrap: wrap;

  .section.featured {
    width: 100%;

    &:not(.has-image) {
      @include media-breakpoint-up('sm') {
        width: 50%;
      }
    }
  }
}

.section.featured {
  @include media-breakpoint-up('sm') {
    display: flex;
    align-items: stretch;
  }

  @include media-breakpoint-up('xxxl') {
    padding-left: var(--app-outline-width);
    padding-right: var(--app-outline-width);
  }

  @include media-breakpoint-up('huge') {
    padding-left: calc(var(--app-outline-width) * 2);
    padding-right: calc(var(--app-outline-width) * 2);
  }

  .section-content {
    .headline {
      margin-top: var(--app-outline-width);
      color: var(--current-color);

      @include media-breakpoint-up('sm') {
        margin-top: 0;
      }
    }

    .section-text {
      margin: var(--app-outline-width) 0;
    }
  }

  .image,
  .video-link {
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: color('charcoal-light');
    border-radius: var(--app-outline-radius);
    color: color('white');
    overflow: hidden;

    a {
      display: inline-block;
    }

    img {
      display: inline-block;
      object-fit: cover;
    }
  }

  .video-link {
    &::before {
      @include icon('\25b6', 3.25em, color('white'));

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      padding: 0.5em;
      border: 3px solid color('white');
      border-radius: 100%;
      transform: translate(-50%, -50%);

      @media (prefers-reduced-motion: no-preference) {
        transition: transform 0.35s ease-out;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: radial-gradient(circle, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 100%);

      @media (prefers-reduced-motion: no-preference) {
        transition: opacity 0.7s ease-out;
      }
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      &::before {
        transform: translate(-50%, -50%) scale(1.25);
      }

      &::after {
        opacity: 0.5;
      }
    }
  }

  /**
   * Reverse layout
   */
  &.l-reverse {
    .section-content {
      .headline,
      .link {
        color: color('white');
      }

      .section-text {
        &:last-child {
          margin-bottom: 0;
        }
      }

      /** Gradient effect on rollover */
      @include media-breakpoint-up('sm') {
        @media (prefers-reduced-motion: no-preference) {
          .link {
            background-image: linear-gradient(
              to right,
              color('white') 0%,
              rgba(color('white', false), 0.6666) 16.6667%,
              rgba(color('white', false), 0.6666) 33.3333%,
              color('white') 50%
            );
          }
        }
      }
    }
  }

  /**
   * Sections with images
   */
  &.has-image {
    .section-content {
      width: calc(100% - var(--app-outline-width) * 4);
      position: relative;

      @include media-breakpoint-up('sm') {
        width: calc(50% - var(--app-outline-width));
      }

      @include media-breakpoint-up('xl') {
        width: calc(50% - var(--app-outline-width) * 2);
      }

      &:first-child {
        z-index: 2;
        margin-left: calc(var(--app-outline-width) * 4);

        @include media-breakpoint-up('sm') {
          padding: calc(var(--app-outline-width) * 2) 0;
          margin-left: 0;
        }
      }

      &:last-child {
        position: relative;
        z-index: 1;
        margin-top: calc(var(--app-outline-width) * -8);

        @include media-breakpoint-up('sm') {
          margin-top: 0;
        }
      }

      .section-text {
        padding: var(--app-outline-width) calc(var(--app-outline-width) * 2);
        background-color: color('white');
        background-image: linear-gradient(
          115deg,
          rgba(color('charcoal', false), 0.025) 0%,
          color('white') 43%,
          color('white') 57%,
          rgba(color('charcoal', false), 0.025) 100%
        );
        border-color: var(--current-color);
        border-style: solid;
        border-radius: var(--app-outline-radius);
        border-width: 1px;
      }

      .link.button {
        position: absolute;
        z-index: 2;
        background-color: color('white');

        @include media-breakpoint-up('sm') {
          position: relative;
          background-color: transparent;
        }
      }
    }

    &:nth-child(odd) {
      .section-content {
        &:first-child {
          order: 2;

          .section-text {
            @include media-breakpoint-up('sm') {
              border-left: none;
              border-radius: 0 var(--app-outline-radius) var(--app-outline-radius) 0;
            }
          }

          .headline,
          .link {
            margin-left: calc(var(--app-outline-width) * 2);
          }
        }

        &:last-child {
          order: 1;
        }
      }
    }

    &:nth-child(even) {
      justify-content: flex-end;

      .section-content {
        &:first-child {
          @include media-breakpoint-up('sm') {
            text-align: right;
          }

          .section-text {
            @include media-breakpoint-up('sm') {
              border-right: none;
              border-radius: var(--app-outline-radius) 0 0 var(--app-outline-radius);
            }
          }

          .headline,
          .link {
            margin-right: calc(var(--app-outline-width) * 2);
            text-align: left;
          }

          .headline {
            display: inline-block;
          }
        }
      }
    }

    /**
     * Reverse layout
     */
    &.l-reverse {
      color: color('white');

      .section-content {
        position: relative;

        &:first-child {
          z-index: 2;
          padding: calc(var(--app-outline-width) * 2);
          background-size: 200%;
          background-position: center;
          border-radius: var(--app-outline-radius);

          @include media-breakpoint-up('xxl') {
            padding: calc(var(--app-outline-width) * 3);
          }
        }

        &:last-child {
          @include media-breakpoint-up('sm') {
            padding: calc(var(--app-outline-width) * 4) 0;
          }

          @include media-breakpoint-up('xl') {
            padding: calc(var(--app-outline-width) * 3) 0;
          }
        }

        .section-text {
          padding-left: 0;
          padding-right: 0;
          background-color: transparent;
          background-image: none;
          /* stylelint-disable-next-line declaration-no-important -- Override the odd / even rules */
          border: none !important;
          font-size: 1.1429em;
          text-align: left;
        }

        .headline,
        .link {
          /* stylelint-disable-next-line declaration-no-important -- Override the odd / even rules */
          margin-left: 0 !important;
        }

        .headline {
          @include media-breakpoint-up('xxs') {
            font-size: 1.0937em;
          }

          @include media-breakpoint-up('lg') {
            font-size: 1.1875em;
          }
        }

        .link.button {
          position: relative;
          background-color: transparent;
        }
      }

      &:nth-child(odd) {
        .section-content {
          &:first-child {
            order: 1;
          }

          &:last-child {
            order: 2;
          }
        }

        .image,
        .video-link {
          @include media-breakpoint-up('sm') {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &:nth-child(even) {
        .section-content {
          &:first-child {
            order: 2;
            text-align: left;
          }

          &:last-child {
            order: 1;
          }
        }

        .image,
        .video-link {
          @include media-breakpoint-up('sm') {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    &.has-count-up {
      /**
       * Reverse layout
       */
      &.l-reverse {
        .section-content {
          &:last-child {
            @include media-breakpoint-up('md') {
              padding-bottom: calc(var(--app-outline-width) * 8);
            }

            @include media-breakpoint-up('lg') {
              padding-bottom: calc(var(--app-outline-width) * 4);

              .image {
                height: calc(100% - var(--app-outline-width) * 3);

                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Sections without images
   */
  &:not(.has-image) {
    .section-content {
      padding: 0 calc(var(--app-outline-width) * 2);

      @include media-breakpoint-up('lg') {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      &:first-child {
        text-align: right;

        @include media-breakpoint-up('sm') {
          text-align: left;
        }
      }

      .headline {
        display: inline-block;
        text-align: left;

        @include media-breakpoint-up('lg') {
          width: 13rem;
          font-size: 0.8em;
        }
      }

      .section-text {
        margin-top: calc(var(--app-outline-width) * 2);
        text-align: left;

        @include media-breakpoint-up('lg') {
          width: calc(100% - 13rem);
          padding-left: var(--app-outline-width);
          margin-top: 0;
        }
      }

      .link {
        margin-top: var(--app-outline-width);
        float: left;

        @include media-breakpoint-up('sm') {
          float: none;
        }

        @include media-breakpoint-up('md') {
          margin-top: 0;
        }

        @include media-breakpoint-up('lg') {
          align-self: flex-end;
        }
      }
    }

    /**
     * Reverse layout
     */
    &.l-reverse {
      width: calc(100% - var(--app-outline-width) * 4);

      @include media-breakpoint-up('sm') {
        width: calc(50% - var(--app-outline-width));
      }

      @include media-breakpoint-up('xl') {
        width: calc(50% - var(--app-outline-width) * 2);
      }

      @include media-breakpoint-up('xxxl') {
        width: calc(50% - var(--app-outline-width) * 3);
      }

      @include media-breakpoint-up('huge') {
        width: calc(50% - var(--app-outline-width) * 2);
      }

      .section-content {
        padding: calc(var(--app-outline-width) * 2);
        border-radius: var(--app-outline-radius);

        .link {
          float: none;
        }
      }
    }
  }

  /** This allows up to 6 blocks without images to follow each other */
  &.has-image + &:not(.has-image),
  &.has-image + &:not(.has-image) + &:not(.has-image) + &:not(.has-image),
  &.has-image + &:not(.has-image) + &:not(.has-image) + &:not(.has-image) + &:not(.has-image) + &:not(.has-image) {
    &:not(.l-reverse) {
      .section-content {
        @include media-breakpoint-up('sm') {
          padding-left: var(--app-outline-width);
          padding-right: calc(var(--app-outline-width) * 2);
        }
      }
    }

    &.l-reverse {
      margin-left: calc(var(--app-outline-width) * 4);

      @include media-breakpoint-up('sm') {
        margin-left: 0;
        margin-right: var(--app-outline-width);
      }

      @include media-breakpoint-up('xl') {
        margin-right: calc(var(--app-outline-width) * 2);
      }

      @include media-breakpoint-up('xxxl') {
        margin-left: calc(var(--app-outline-width) * 2);
        margin-right: var(--app-outline-width);
      }

      @include media-breakpoint-up('huge') {
        margin-right: 0;
      }
    }
  }

  &.has-image + &:not(.has-image) + &:not(.has-image),
  &.has-image + &:not(.has-image) + &:not(.has-image) + &:not(.has-image) + &:not(.has-image),
  &.has-image
    + &:not(.has-image)
    + &:not(.has-image)
    + &:not(.has-image)
    + &:not(.has-image)
    + &:not(.has-image)
    + &:not(.has-image) {
    &:not(.l-reverse) {
      .section-content {
        @include media-breakpoint-up('sm') {
          padding-left: calc(var(--app-outline-width) * 2);
          padding-right: var(--app-outline-width);
        }
      }
    }

    &.l-reverse {
      @include media-breakpoint-up('sm') {
        margin-left: var(--app-outline-width);
      }

      @include media-breakpoint-up('xl') {
        margin-left: calc(var(--app-outline-width) * 2);
      }

      @include media-breakpoint-up('xxxl') {
        margin-left: var(--app-outline-width);
        margin-right: calc(var(--app-outline-width) * 2);
      }

      @include media-breakpoint-up('huge') {
        margin-left: 0;
      }
    }
  }
}
