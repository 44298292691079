.template {
  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.35s ease-in 0s alternate 1 backwards;
  }

  ul {
    list-style: none;
    padding-left: 1.5em;
  }

  ul li::before {
    @include icon('\e906', 1em, var(--current-color));

    display: inline-block;
    position: absolute;
    transform: translate(-1.5em, 0.125em);
  }

  a[target='_blank']:not(.button):not(.video-link):not([style]) {
    position: relative;

    &::after {
      @include icon('\e907', 0.775rem, color('primary'));

      content: ' \e907';
      transition: color 0.25s ease-out;
    }

    &:hover,
    &:focus {
      &::after {
        color: color('secondary');
      }
    }
  }

  &-header {
    padding: calc(var(--app-outline-width) * 2);
    border-radius: var(--app-outline-radius);

    @include media-breakpoint-up('md') {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
    }

    @include media-breakpoint-up('xl') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.has-link:not(.has-headline) {
      @include media-breakpoint-up('sm') {
        align-items: center;
      }

      @include media-breakpoint-between('sm', 'md') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    h2.headline {
      @include media-breakpoint-up('xxs') {
        font-size: 1.1875em;
      }
    }

    .link {
      text-align: left;
      white-space: nowrap;
    }

    &.l-vegetables {
      position: relative;
      padding-right: calc(var(--app-outline-width) * 4);
      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: url('../../../images/vegetables-corner-960.png') no-repeat 135%
          calc(100% + var(--app-outline-width) / 2) / calc(75% - var(--app-outline-width) * 2) auto;
        opacity: 0.4;

        @include media-breakpoint-up('md') {
          background-image: url('../../../images/vegetables-corner-1440.png');
          background-position: calc(100% - var(--app-outline-width) / 8) calc(100% - var(--app-outline-width) / 8);
          background-size: auto calc(100% - var(--app-outline-width) / 4);
          opacity: 0.5;
        }

        @include media-breakpoint-up('xl') {
          background-image: url('../../../images/vegetables-corner.svg');
        }
      }
    }
  }

  &-title {
    display: inline-block; // ? TODO display block
    margin: 0;
    font-family: $font-family-italic;
    font-size: 1.8095em;
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: 0.9em;
    text-transform: none;

    &:only-child {
      display: block;
      padding: calc(var(--app-outline-width) * 2) 0;
      margin-bottom: 0;
      text-align: center;

      @include media-breakpoint-up('sm') {
        padding: calc(var(--app-outline-width) / 2) 0;
      }

      @include media-breakpoint-up('md') {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      @include media-breakpoint-up('xl') {
        flex-grow: 1;
      }
    }

    .template-header.has-headline & {
      text-align: right;
    }

    .template-header.has-logo & {
      line-height: 0.8em;

      span {
        display: inline-block;
        text-align: right;
        line-height: 0.4em;
      }
    }

    .link {
      text-decoration: none;
    }

    img {
      display: block;
      width: auto;
      max-width: none;
      height: 3.8095rem;
      min-height: 3.8095rem;
      filter: brightness(0) invert(1);
    }

    & + .template-header-link {
      @include media-breakpoint-up('md') {
        padding-left: calc(var(--app-outline-width) * 2);
        text-align: right;
      }

      @include media-breakpoint-up('xxl') {
        padding-left: calc(var(--app-outline-width) * 3);
      }
    }
  }

  &-headline {
    padding-top: calc(var(--app-outline-width) * 4);

    @include media-breakpoint-up('xs') {
      padding-top: calc(var(--app-outline-width) * 3);
    }

    @include media-breakpoint-up('sm') {
      padding-top: calc(var(--app-outline-width) * 2);
    }

    @include media-breakpoint-up('md') {
      padding-top: 0;
      padding-left: calc(var(--app-outline-width) * 2);
      transform: translateY(0.2em);
    }

    @include media-breakpoint-up('xxl') {
      padding-left: calc(var(--app-outline-width) * 3);
    }

    > div {
      font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'lnum';
      font-size: 1.4286em;
      font-weight: $font-weight-bold;
      line-height: $line-height-sm;
      text-transform: uppercase;

      span[style*='underline'] {
        /* stylelint-disable-next-line declaration-no-important -- Override the base typographic rules */
        text-decoration-color: rgba(color('white', false), 0.4) !important;
        text-underline-offset: 0.225em;
      }

      sup {
        padding-left: 0.1em;
        font-size: 0.575em;
      }
    }

    > p {
      font-family: $font-family-italic;
      font-size: 1.1429em;
      font-style: italic;
      font-weight: $font-weight-light;
      line-height: $line-height-sm;
    }

    & + .template-header-link {
      @include media-breakpoint-up('md') {
        margin-top: var(--app-outline-width);
      }

      @include media-breakpoint-up('xl') {
        padding-left: calc(var(--app-outline-width) * 2);
        margin-top: 0;
      }

      @include media-breakpoint-up('xxl') {
        padding-left: calc(var(--app-outline-width) * 3);
      }
    }
  }

  &-header-link {
    padding-top: calc(var(--app-outline-width) * 4);

    @include media-breakpoint-up('xs') {
      padding-top: calc(var(--app-outline-width) * 3);
    }

    @include media-breakpoint-up('sm') {
      padding-top: calc(var(--app-outline-width) * 2);

      .template-header.has-link:not(.has-headline) & {
        padding-top: 0;
      }
    }

    @include media-breakpoint-up('md') {
      padding-top: 0;
    }

    @include media-breakpoint-between('md', 'xl') {
      .template-header.has-link.has-headline & {
        text-align: right;
      }
    }
  }

  &-content {
    padding: calc(var(--app-outline-width) * 1.5) 0;
  }

  &-footer {
    padding-bottom: calc(var(--app-outline-width) * 3);

    &-nav {
      .article {
        /* stylelint-disable-next-line declaration-no-important -- Override .article.press */
        position: absolute !important;
        top: 50%;
        /* stylelint-disable-next-line declaration-no-important -- Override .article.press */
        width: 18em !important;

        @include media-breakpoint-up('xs') {
          /* stylelint-disable-next-line declaration-no-important -- Override .article.press */
          width: 22.5em !important;
        }

        @media (prefers-reduced-motion: no-preference) {
          transition: transform 0.35s ease-out;
        }
      }

      .nav-prev,
      .nav-next {
        cursor: pointer;
        position: fixed;
        top: 50%;
        z-index: 110;

        > .button {
          width: 2.5em;
          height: 2.5em;
          display: inline-block;
          background-color: color('white');
          border: 1px solid var(--current-color);
          border-radius: 100%;
          text-align: center;

          > .icon {
            display: inline-block;
            vertical-align: middle;
            transform: translateY(-0.5em);

            @include media-breakpoint-up('md') {
              transform: translateY(calc(var(--app-outline-width) * -0.4));
            }
          }

          @include media-breakpoint-up('md') {
            width: calc(var(--app-outline-width) * 2);
            height: calc(var(--app-outline-width) * 2);
          }
        }
      }

      .nav-prev {
        left: -1.25em;

        @include media-breakpoint-up('md') {
          left: calc(var(--app-outline-width) * -1);
        }

        > .button {
          transform: rotate(90deg);
        }

        .article {
          left: 0;
          transform: translate(-100%, -50%);
        }

        &:hover .article,
        .button.is-hover + .article {
          transform: translate(1em, -50%);

          @include media-breakpoint-up('md') {
            transform: translate(var(--app-outline-width), -50%);
          }
        }
      }

      .nav-next {
        right: -1.25em;

        @include media-breakpoint-up('md') {
          right: calc(var(--app-outline-width) * -1);
        }

        > .button {
          transform: rotate(-90deg);
        }

        .article {
          right: 0;
          transform: translate(100%, -50%);
        }

        &:hover .article,
        .button.is-hover + .article {
          transform: translate(-1em, -50%);

          @include media-breakpoint-up('md') {
            transform: translate(calc(var(--app-outline-width) * -1), -50%);
          }
        }
      }

      .nav-back {
        text-align: center;
      }
    }
  }
}
