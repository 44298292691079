.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 109;
  padding: var(--app-outline-width);
  background-color: var(--app-outline-color);
  overflow: scroll;

  @include scrollbar(color('secondary'));

  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
    transition: opacity 0.25s ease-in;

    &.is-closing {
      opacity: 0;
    }
  }

  /** Top & bottom borders */
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: var(--app-outline-width);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--app-outline-color);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  > div {
    /** Top left & right rounded corners */
    @include top-corners(false);
  }

  &-content {
    position: absolute;
    top: var(--app-outline-width);
    right: var(--app-outline-width-right);
    bottom: var(--app-outline-width);
    left: var(--app-outline-width);
    border-radius: var(--app-outline-radius);

    /** Bottom left & right rounded corners */
    @include bottom-corners(false);
  }

  &-close-button {
    position: fixed;
    top: calc(var(--app-outline-width) * 2);
    right: calc(var(--app-outline-width) * 2);
    z-index: 99;
    text-align: center;

    .icon,
    span:not(.icon) {
      transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    }

    .icon {
      display: inline-block;
      font-size: 1.7143em;
    }

    span:not(.icon) {
      display: block;
      opacity: 0.8;
      font-size: 0.5714em;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      .icon {
        opacity: 0.8;
        transform: scale(0.9);
      }

      span:not(.icon) {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}
