.section.contact {
  .section {
    &-header {
      @include media-breakpoint-up('sm') {
        display: flex;
        align-items: flex-start;
      }

      .headline {
        > span {
          &:nth-child(1) {
            margin-left: 1.25em;
          }
        }
      }

      > div {
        margin-top: calc(var(--app-outline-width) * 2);

        @include media-breakpoint-up('sm') {
          padding-left: calc(var(--app-outline-width) * 2);
          margin-top: 0;
        }

        @include media-breakpoint-up('lg') {
          padding-left: calc(var(--app-outline-width) * 3);
        }

        @include media-breakpoint-up('xl') {
          padding-left: calc(var(--app-outline-width) * 4);
        }

        @include media-breakpoint-up('xxl') {
          padding-left: calc(var(--app-outline-width) * 5);
        }
      }
    }
  }
}
