.date-filter {
  .menu {
    z-index: 10;
  }

  .filter {
    &-fields {
      display: flex;
      align-items: center;
      width: 100%;

      .field {
        flex-grow: 1;
        width: 100%;
      }

      .date-field:last-child {
        transform: translateX(calc(var(--app-outline-width) / 4));
      }

      .label {
        @include visually-hidden;
      }

      .icon {
        &-chevron-down {
          display: inline-block;
          font-size: 1.2em;
          transform: rotate(-90deg);
        }

        &-exclamation {
          color: color('raspberry-light');
          font-size: 0.75rem;
        }
      }
    }

    &-overlay {
      width: 100%;

      @include media-breakpoint-up('xs') {
        width: 16em;
      }
    }
  }
}
