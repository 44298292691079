.template.template-my-events {
  .template {
    &-banner {
      .template-header {
        &:first-child {
          order: 2;

          .template-title {
            margin-bottom: 0.125em;
          }

          ul {
            font-size: 0.7619em;

            li {
              font-family: $font-family-italic;
              font-style: italic;
            }
          }
        }

        &:last-child {
          order: 1;
        }
      }
    }

    &-header {
      h3.headline {
        margin-top: -0.5em;

        @include media-breakpoint-up('xxs') {
          font-size: 1.1875em;
        }
      }
    }
  }
}
