.beegreen {
  @keyframes beegreen {
    0% {
      fill: #72be1e;
    }

    5% {
      fill: #767362;
    }

    100% {
      fill: #767362;
    }
  }

  @keyframes beegreen-eye {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 1;
    }

    2% {
      opacity: 0;
    }

    3% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .bee {
      .eye {
        animation: beegreen-eye 10s infinite alternate backwards 1.2s;
      }

      .fill,
      .eye {
        transition: fill 0.25s ease-out;
      }
    }

    .letter {
      animation: beegreen 10s infinite alternate backwards;

      &:nth-child(2) {
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
      }

      &:nth-child(5) {
        animation-delay: 0.4s;
      }

      &:nth-child(6) {
        animation-delay: 0.5s;
      }

      &:nth-child(7) {
        animation-delay: 0.6s;
      }

      &:nth-child(8) {
        animation-delay: 0.7s;
      }

      &:nth-child(9) {
        animation-delay: 0.8s;
      }
    }
  }
}

.link.beegreen-link {
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within {
    .beegreen {
      .bee {
        .fill {
          fill: #fff;
        }

        .eye {
          fill: #72be1e;
        }
      }
    }
  }
}
