.filter {
  .filter {
    &-button {
      position: relative;
      font-size: 1.2381em;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      transform: translateY(0.05em);

      &::after {
        @include icon('\e909', 1em, currentcolor);

        display: inline-block;
        vertical-align: bottom;
        margin-left: 0.25em;
      }

      &:focus {
        color: var(--current-color);
      }

      [class*='bg-'] &:focus {
        color: color('white');
      }
    }

    &-label {
      position: absolute;
      bottom: -0.5em;
      left: 0;
      opacity: 0.5;
      font-family: $font-family-italic;
      font-size: 0.6154em;
      font-style: italic;
      text-transform: none;

      @include media-breakpoint-up('sm') {
        bottom: -0.125em;
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: appear-half 0.25s ease-in 0s alternate 1 backwards;
      }
    }

    &-active-filters {
      .button {
        padding: 0.2em 0.5em 0.1em 0.5em;
        border: 1px solid var(--current-color);
        border-radius: 5em;
        font-size: 0.8571em;
        font-weight: $font-weight-normal;
        white-space: nowrap;

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-in 0s alternate 1 backwards;
          transition: opacity 0.25s ease-in;

          &.is-closing {
            opacity: 0;
          }
        }

        .icon {
          display: inline-block;
          margin-right: 0.3333em;
          color: var(--current-color);
          font-size: 0.6667em;
          transform: translateY(-0.05em);

          &-chevron-down {
            display: inline-block;
            margin: 0 -0.25em;
            transform: rotate(-90deg);
          }
        }

        [class*='bg-'] & {
          border-color: color('white');

          .icon {
            color: color('white');
            text-shadow: none;
          }
        }

        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          .icon-xmark-bold {
            color: color('raspberry-light');
          }
        }
      }
    }
  }
}
