.section.create-festival {
  .section {
    &-header {
      .headline {
        > span {
          &:nth-child(1) {
            margin-left: 1.25em;
          }
        }
      }
    }
  }
}
