.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--app-outline-radius);
  font-size: 0.9524em;

  thead {
    th {
      padding: 0 0.5rem;
      opacity: 0.6;
      font-size: 0.65em;
      font-weight: $font-weight-light;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(color('primary', false), 0.25);

      .section-content[class*='bg-'] & {
        border-bottom-color: rgba(color('white', false), 0.5);
      }
    }

    td {
      vertical-align: top;
      padding: 0.5rem 0.5rem 0.3333rem 0.5rem;
      line-height: $line-height-sm;
    }

    @include media-breakpoint-down('md') {
      display: block;

      tr {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        padding: 1em 0;
      }

      td {
        display: block;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:nth-child(n + 1) {
          width: 100%;
        }

        @for $i from 1 through 12 {
          &:nth-child(#{$i})::before {
            content: var(--col-#{$i}-label) ' : ';
            opacity: 0.6;
            font-size: 0.8em;
          }
        }
      }
    }
  }

  &:not(.has-pagination) {
    tbody {
      tr:last-child {
        border-bottom: none;
      }
    }
  }

  &.has-pagination {
    min-height: calc(2.75em + var(--app-outline-width) * 2);
    margin-bottom: calc(var(--app-outline-width) / 2);
  }
}
