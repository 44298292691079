.checkbox-group .checkbox-label,
.radio-group .radio-label {
  display: block;
  line-height: $line-height-sm;

  &.is-disabled {
    opacity: 0.5;
  }

  &:not(.is-disabled) {
    cursor: pointer;
  }
}

.checkbox-group .checkbox,
.radio-group .radio {
  display: inline-block;
  position: relative;
  width: 0.7619em;
  height: 0.7619em;
  margin-right: 0.3333em;
  border: 1px solid currentcolor;
  border-radius: 4px;

  &.is-selected {
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      border-radius: 2px;
      animation: appear 0.15s ease-in 0s alternate 1 backwards;
    }
  }

  &.is-focused {
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border: 1px solid currentcolor;
      border-radius: 5px;
      opacity: 0.5;
    }
  }
}

.checkbox-group,
.radio-group {
  .field-description,
  .field-error {
    padding-right: 0;
    text-align: left;
  }
}

.checkbox-group .checkbox.is-selected {
  &::before {
    @include icon('\2714', 0.6667em, currentcolor);

    left: 0;
    right: 0;
    line-height: 0.7619em;
    text-align: center;
  }
}

.radio-group .radio.is-selected {
  &::before {
    left: 2px;
    right: 2px;
    background-color: currentcolor;
  }
}
