/**
 * @see ./_press.scss
 */

.article.festival {
  > .link {
    flex-direction: column;
  }

  .article {
    &-header {
      width: 100%;
      min-width: 100%;
      padding: var(--app-outline-width);
      text-align: left;

      @include media-breakpoint-up('md') {
        padding: calc(var(--app-outline-width) / 2);
      }

      .image img {
        height: 2.5em;
        margin: 0;
      }
    }

    &-title {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      font-family: $font-family-italic;
      font-style: italic;
      font-weight: $font-weight-bold;
      line-height: 0.4em;

      .image {
        display: inline-block;
      }
    }

    &-content {
      min-height: auto;

      > div {
        flex-grow: 1;
      }
    }
  }
}
