/**
 * The .template-create-event class should be applied in addition to .template-single-event
 */
.template.template-create-event {
  padding-bottom: calc(var(--app-outline-width) * 1.5);

  --fields-overflow: calc(var(--app-outline-width) / 2);

  @include media-breakpoint-up('sm') {
    --fields-overflow: 0.6666rem;
  }

  .form.create-event-form {
    display: flex;
    flex-direction: column;

    &.is-disabled {
      .form-submit,
      .event-dates .field-description,
      .event-dates .delete-button {
        display: none;
      }
    }

    .template-content {
      padding-bottom: calc(var(--app-outline-width) / 2);
    }

    .template-footer {
      order: 99;

      & + .hr {
        margin-bottom: calc(var(--app-outline-width) * 2);
      }
    }
  }

  .form:not(.is-disabled) {
    .menu-button.is-required,
    legend.is-required,
    .legend.is-required,
    .date-time-range.is-required {
      &::after {
        content: ' *';
        color: color('raspberry-light');
        font-family: $font-family-serif;
        font-style: normal;
        font-weight: $font-weight-bold;
        white-space: nowrap;
      }
    }
  }

  .menu {
    z-index: 75;
    border-color: color('charcoal-dark');

    legend,
    .legend {
      padding-right: 1.5em;
      margin-top: 0.25em;
      font-weight: $font-weight-medium;
      line-height: $line-height-sm;
      text-transform: uppercase;
    }

    &-button {
      &.has-warning {
        color: color('raspberry');
      }
    }
  }

  .field:not(.checkbox-group):not(.radio-group) label,
  .event-taxonomy-button,
  .location-button,
  .event-date-button,
  .promoter-button,
  .partner-button {
    text-decoration: underline solid rgba(color('primary', false), 0.3);
    text-underline-offset: 0.15em;
    transition: text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;

    &.is-disabled {
      text-decoration-color: transparent;
    }
  }

  .field:not(.checkbox-group):not(.radio-group).is-disabled label {
    text-decoration-color: transparent;
  }

  .field:not(.checkbox-group):not(.radio-group):hover label,
  .event-taxonomy-button:hover,
  .event-taxonomy-button:focus,
  .event-taxonomy-button:focus-visible,
  .event-taxonomy-button:focus-within,
  .location-button:hover,
  .location-button:focus,
  .location-button:focus-visible,
  .location-button:focus-within,
  .event-date-button:hover,
  .event-date-button:focus,
  .event-date-button:focus-visible,
  .event-date-button:focus-within,
  .promoter-button:hover,
  .promoter-button:focus,
  .promoter-button:focus-visible,
  .promoter-button:focus-within,
  .partner-button:hover,
  .partner-button:focus,
  .partner-button:focus-visible,
  .partner-button:focus-within {
    text-decoration: underline solid rgba(color('primary', false), 0);
    text-underline-offset: 0.075em;
  }

  .field {
    p {
      margin-bottom: 0;
      line-height: $line-height-sm;
    }

    &-control {
      position: relative;
      width: 100%;

      label,
      .label {
        display: block;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
        font-size: inherit;
        text-overflow: '...';
        // background-color: #f051;

        @include media-breakpoint-up('xs') {
          min-width: 100%;
        }
      }

      input:not([type='radio']):not([type='checkbox']),
      .input,
      select,
      textarea {
        padding: 0.25rem calc(var(--fields-overflow) - 1px);

        &:not(:focus) {
          // opacity: 0.4;
          opacity: 0;
        }

        &:focus {
          z-index: 75;
        }
      }

      input,
      textarea {
        position: absolute;
        left: calc(var(--fields-overflow) * -1);
        right: calc(var(--fields-overflow) * -1);
        width: calc(100% + var(--fields-overflow) * 2);
        max-width: calc(100% + var(--fields-overflow) * 2);
        background-color: color('white');
        transition: border-color 0.25s ease-out, opacity 0.25s ease-out;
      }

      input:not([type='radio']):not([type='checkbox']) {
        margin-top: calc(-0.25rem + 1px);
      }

      textarea {
        top: calc(var(--fields-overflow) * -1);
        bottom: calc(var(--fields-overflow) * -1);
        height: calc(100% + var(--fields-overflow) * 2);
        min-height: calc(100% + var(--fields-overflow) * 2);
        max-height: calc(100% + var(--fields-overflow) * 2);
        min-width: calc(100% + var(--fields-overflow) * 2);
        padding: calc(var(--fields-overflow) - 2px) calc(var(--fields-overflow) - 1px);
        line-height: inherit;
      }
    }

    &-description,
    &-error {
      padding-right: 0;
      margin-top: 0;
      font-family: $font-family-italic;
      font-size: 0.7619rem;
      font-style: italic;
      line-height: $line-height-sm;
      text-align: left;
    }

    &-error {
      color: color('raspberry-light');
      font-weight: $font-weight-bold;
      animation: appear 0.25s ease-in 0s alternate 1 backwards;
    }

    &.textarea-field {
      display: flex;
      flex-direction: column;
      height: calc(100% - 4em);

      .field-control {
        order: 2;
        height: 100%;

        label {
          align-self: flex-start;
          line-height: inherit;
        }
      }

      & + .field-group {
        margin-top: var(--app-outline-width);
      }
    }

    &-group {
      margin-bottom: 0.75em;

      label,
      .label {
        min-width: 0;
      }

      .row {
        display: flex;
        margin-top: 0.5em;
        gap: var(--app-outline-width);

        > .field {
          width: 50%;
        }

        .field + .field {
          margin-top: 0;
        }
      }

      fieldset {
        legend {
          margin-bottom: 0.75em;
        }
      }

      .button .field-error,
      & + .field-description {
        font-family: $font-family-italic;
        font-size: 0.7619em;
        font-style: italic;
        line-height: $line-height-sm;
        animation: appear 0.25s ease-in 0s alternate 1 backwards;
      }

      .button .field-error {
        display: block;
        margin-top: -0.125em;
        color: color('raspberry-light');
        font-weight: $font-weight-bold;
        text-decoration: none;
      }

      & + .field-description {
        margin-top: 0.25em;
      }
    }
  }

  .file-field {
    @include visually-hidden;
  }

  .radio-group,
  .checkbox-group {
    &.l-inline {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25em 0.75em;
      margin-top: 0.5em;

      .field-description,
      .field-error {
        width: 100%;
        margin-top: -0.25em;
      }
    }
  }

  .template {
    &-banner {
      .template-header:first-child {
        .template-title {
          display: block;
          margin-bottom: 0.5em;

          & + div {
            margin-bottom: 0.75em;
          }
        }

        ul {
          @media (prefers-reduced-motion: no-preference) {
            animation: appear 0.25s ease-in 0s alternate 1 backwards;
          }

          > li.event-taxonomy {
            position: relative;
            font-family: $font-family-sans;
            font-style: normal;

            .menu-button,
            .field-error {
              font-family: $font-family-italic;
              font-style: italic;
            }

            .menu {
              top: calc(var(--app-outline-width) * -0.5);
              left: 0;
              font-size: 1.3125em;
            }

            .field-error {
              display: inline-block;
              margin-left: 1em;
              color: color('raspberry-light');
              font-family: $font-family-italic;
              font-style: italic;
              // font-weight: $font-weight-bold;
            }
          }
        }
      }
    }

    &-header {
      &:last-child {
        z-index: 75;
      }

      .field input {
        /* stylelint-disable-next-line declaration-no-important */
        margin-top: 0 !important;
      }

      .template-title {
        .field input {
          font-family: $font-family-italic;
          font-style: italic;
          font-weight: $font-weight-bold;
        }
      }

      .festivals-filter {
        display: flex;
        align-items: flex-end;
        z-index: 75;

        .filter-button {
          align-items: flex-end;

          > p,
          > span {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color 0.25s ease-out;
          }

          &:focus p,
          &:focus span {
            border-bottom-color: rgba(color('white', false), 0.4);
          }

          > span {
            font-size: 1.8095em;
          }

          img {
            margin-top: 0;
            max-height: none;
            height: 3.8095rem;
          }
        }

        .menu.l-dropdown {
          border-color: rgba(color('primary', false), 0.2);
        }
      }
    }

    &-content {
      .event {
        &-info {
          textarea {
            @include scrollbar(var(--current-color));
          }
        }

        &-image-wrap {
          display: flex;
          align-self: flex-start;
          position: relative;
          width: 100%;
          background-image: linear-gradient(
            115deg,
            rgba(color('charcoal', false), 0.025) 0%,
            color('white') 43%,
            color('white') 57%,
            rgba(color('charcoal', false), 0.025) 100%
          );
          border-radius: var(--app-outline-radius);

          &:not(.has-image) {
            border: 1px solid rgba(color('charcoal', false), 0.125);
          }

          .menu {
            top: calc(var(--fields-overflow) * -1);
            right: calc(var(--fields-overflow) * -1);
            // bottom: calc(var(--fields-overflow) * -1);
            left: calc(var(--fields-overflow) * -1);
            min-height: calc(100% + 1.3333rem);
            margin-left: 0;
            overflow: hidden;
          }

          .menu-button {
            width: 100%;
            height: 100%;

            &::before {
              content: '';
              padding-top: 66.6666%;
              float: left;
            }

            &:disabled,
            &.is-disabled {
              opacity: 1;
            }

            .button {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            }
          }

          .image img {
            animation: appear 0.25s ease-in 0s alternate 1 backwards;
          }

          &.has-image .menu-button .button span::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(color('black', false), 0.3333);
            border-radius: 5rem;
            z-index: -1;
            mix-blend-mode: color;
          }

          > .field-description,
          > .field-error {
            position: absolute;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
          }

          > .field-description {
            top: 0.25em;
          }

          > .field-error {
            bottom: 0.25em;
          }

          &.has-image > .field-error {
            text-shadow: 0 0 1px color('white');
            -webkit-text-stroke-width: 0.5px;
            -webkit-text-stroke-color: color('white');
          }

          .gallery {
            margin-top: 0.75em;

            > div {
              height: 4.75em;
            }

            ul {
              position: absolute;
              left: 0;
              right: 0;
              display: flex;
              gap: calc(var(--app-outline-width) / 4);
              padding: 0 var(--app-outline-width);
              overflow: scroll;
              overflow-y: hidden;
              height: 4.75em;

              @include scrollbar(color('nature'));
            }

            li {
              margin-bottom: 0;
              height: 4em;

              &::before {
                display: none;
              }

              .image {
                img {
                  width: 4em;
                  max-width: 4em;
                  height: 4em;
                  border-radius: 1em;
                  overflow: hidden;
                }
              }
            }
          }
        }

        &-image-wrap,
        &-promoter-group {
          .delete-button {
            // margin-left: 1.5em;
            font-family: $font-family-italic;
            font-size: 0.7619em;
            font-style: italic;
            animation: appear 0.25s ease-in 0s alternate 1 backwards;

            &:hover,
            &:focus {
              color: color('raspberry-light');
            }
          }
        }

        &-details,
        &-promoter {
          position: relative;

          h3 span:last-child:not(:first-child) > span {
            text-decoration: none;
          }

          > p {
            margin-bottom: 0;
          }

          .menu-button {
            width: 100%;
            transform: none;
          }

          > .field {
            margin-bottom: 0.75em;
          }
        }

        &-location-group,
        &-promoter-group,
        &-partners-group,
        &-reservation-group {
          .menu-button {
            text-align: left;
          }
        }

        &-location-group,
        &-promoter-group {
          .menu {
            top: calc(var(--fields-overflow) * -1);
            right: calc(var(--fields-overflow) * -1);
            left: calc(var(--fields-overflow) * -1);
            min-height: calc(100% + var(--fields-overflow) * 2);
            margin-left: 0;

            fieldset:not(:first-of-type) {
              margin-top: 0.75em;

              legend {
                margin-bottom: 0;
              }
            }

            legend + .field label {
              font-weight: $font-weight-normal;

              .radio + span {
                font-weight: $font-weight-light;
              }
            }
          }

          &.has-location,
          &.has-promoter {
            .button:not(.is-disabled) {
              .p-name::after {
                content: ' *';
                color: color('raspberry-light');
                font-family: $font-family-serif;
                font-weight: $font-weight-bold;
              }
            }

            .menu-button::after {
              display: none;
            }
          }
        }

        &-location-group {
          margin-bottom: 0.25em;

          .menu-button {
            & + .field-error {
              margin-top: -0.25em;
            }
          }
        }

        &-promoter-group {
          p.legend {
            margin-top: 1em;
          }
        }

        &-dates,
        &-partners-group {
          .menu-button,
          .delete-button {
            animation: appear 0.25s ease-in 0s alternate 1 backwards;
          }

          .menu-button {
            flex-grow: 1;
            text-align: left;
          }

          .delete-button {
            padding: 0.25em 0.5em;
            margin-top: 0.125em;
            font-size: 0.75em;
            color: color('raspberry-light');
          }

          .menu {
            top: 0;
            left: calc(var(--fields-overflow) * -1);
            margin-left: 0;
          }

          > .field-group {
            display: flex;
            align-items: flex-start;
            position: relative;
            margin-bottom: 0;

            .menu-button {
              .icon-exclamation {
                display: inline;

                &::before {
                  content: ' \2757';
                  color: color('raspberry-light');
                  font-size: 0.6666rem;
                }
              }
            }
          }
        }

        &-partners-group,
        &-reservation-group {
          margin-bottom: 0;

          .button .icon-blank {
            font-size: 0.75rem;
          }

          .menu {
            right: calc(var(--fields-overflow) * -1);
            min-width: 20em;
            max-width: calc(100% + var(--fields-overflow) * 2 + 1rem);
            margin-left: -1.125rem;

            legend + .field label {
              font-weight: $font-weight-normal;
            }
          }
        }

        &-partners-group {
          .menu {
            font-size: 1.1667em;
          }
        }

        &-reservation-group {
          position: relative;

          .menu {
            top: 0;
            left: calc(var(--fields-overflow) * -1);
            margin-left: 0;
          }
        }

        &-link-group {
          display: inline-block;
          position: relative;

          .menu-button {
            &:disabled,
            &.is-disabled {
              opacity: 1;
            }
          }

          .menu {
            top: calc(var(--fields-overflow) * -1);
            min-width: 20em;

            legend + .field label {
              font-weight: $font-weight-normal;
            }
          }

          > .field-error {
            text-align: center;
          }
        }

        &-dates {
          width: 100%;
          margin-bottom: 0;

          &-button {
            transition: color 0.25s ease-out;
          }

          .date-time-range {
            display: inline;

            .dtr-dates {
              display: inline;
              text-align: left;
            }

            .dtr-times {
              display: inline;
              padding-left: 0;

              &::before {
                content: '🕓 ';
                display: inline;
                margin-right: 0;
              }
            }

            &.has-hours {
              .dtr-dates {
                &::after {
                  @include icon('\e90d', 0.75em, color('white'));
                }
              }
            }
          }

          .date-range-field,
          .time-range-field {
            margin-left: calc(var(--fields-overflow) * -1);

            .label {
              @include visually-hidden;
            }

            .input {
              opacity: 1;
              padding-top: 0.3333em;
              padding-bottom: 0.1667em;
            }

            .field-error,
            .field-description {
              padding-left: var(--fields-overflow);
              margin-top: -0.5em;
              margin-right: -100%;
            }
          }

          fieldset {
            padding-right: 1.5em;
            margin-top: 0;

            .row:first-child {
              margin-top: 0;
            }

            > .field-error {
              padding-left: var(--fields-overflow);
              margin-top: -0.5em;
            }
          }

          .row {
            gap: 0.6666rem;

            > .icon {
              align-self: flex-start;
              margin: 1.125em 0 0 -1em;
            }
          }

          .icon-clock,
          .icon-calendar {
            display: inline-block;
            align-self: center;
            font-size: 0.75em;
            opacity: 0.5;
            transform: translate(-0.5em, -0.05em);
          }
        }

        &-language {
          margin-bottom: calc(var(--app-outline-width) * 1.5);
          text-align: center;

          h3 .icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.3333em;
            transform: translateY(-0.05em);
          }

          &-group {
            position: relative;
          }

          .menu {
            text-align: left;
          }
        }
      }
    }

    &-footer {
      padding-bottom: 0;
      text-align: center;

      .hr {
        margin-bottom: calc(var(--app-outline-width) * 2);
      }

      .button {
        margin-bottom: calc(var(--app-outline-width) * 1.5);
        animation: appear 0.25s ease-in 0s alternate 1 backwards;
      }
    }
  }

  .form-status,
  .form-error,
  .form-sent {
    margin-left: 0;
    text-align: center;
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
  }

  .form-submit {
    padding-bottom: calc(var(--app-outline-width) * 1.5);
    margin-top: 0;

    > div {
      text-align: center;
    }

    &.is-sent {
      @include visually-hidden;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .template-header,
    .event-calendar {
      transition: background-image 0.25s ease-out;
    }
  }
}
