.festivals-filter {
  position: relative;

  .filter-button {
    display: flex;

    &:not(.is-disabled)::after {
      @include icon('\e90b', 1.5em, currentcolor);

      display: inline-block;
      vertical-align: bottom;
      margin-left: 0.25em;
      transform: translateY(0.075em);
    }

    &.is-disabled {
      opacity: 1;
    }

    > span {
      span {
        display: block;
        margin-top: -0.75em;
        font-family: $font-family-italic;
        font-style: italic;
        font-weight: $font-weight-bold;
        text-align: right;
      }
    }

    img {
      margin-top: -1em;
      max-height: 2.5em;
      filter: brightness(0) invert(1);
    }
  }

  .filter-error {
    position: absolute;
    bottom: -1.25em;
    left: 0;
    color: color('raspberry-light');
    font-family: $font-family-italic;
    font-style: italic;
    font-size: 1.2em;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    text-shadow: 0 0 1px color('white');
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: color('white');

    @include media-breakpoint-up('sm') {
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  p.headline {
    display: inline-block;
    vertical-align: bottom;
  }

  .menu {
    padding-top: calc(var(--app-outline-width) * 1);
    color: color('primary');
    text-shadow: none;

    .checkbox-label {
      &:first-child {
        padding-right: 1em;
      }

      .checkbox {
        display: none;
      }

      img {
        max-width: none;
        height: 2.25em;
      }

      &:not(:last-child) {
        margin-bottom: 0.25em;
      }
    }

    .checkbox-group {
      &.has-selection {
        .checkbox-label .checkbox {
          & + span {
            transition: opacity 0.25s ease;
          }

          &:not(.is-selected) + span {
            opacity: 0.4;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
