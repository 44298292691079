/**
 * This is a newer version of the Terfens font than the one provided by Adobe Fonts.
 * This font can be used for user inputs as it contains the "œ" character that is missing in Adobe Fonts version.
 */
@font-face {
  font-family: 'terfens-new'; /* stylelint-disable-line font-family-name-quotes */
  font-weight: normal;
  font-style: normal;

  /* prettier-ignore */
  src:
    url('terfensnormregular-webfont.woff2?nh1') format('woff2'),
    url('terfensnormregular-webfont.woff?nh1') format('woff');
}

// @font-face {
//   font-family: 'terfens-new'; /* stylelint-disable-line font-family-name-quotes */
//   font-weight: 300;
//   font-style: normal;

//   /* prettier-ignore */
//   src:
//     url('terfensnormlight-webfont.woff2?nh1') format('woff2'),
//     url('terfensnormlight-webfont.woff?nh1') format('woff');
// }

// @font-face {
//   font-family: 'terfens-new'; /* stylelint-disable-line font-family-name-quotes */
//   font-weight: 500;
//   font-style: normal;

//   /* prettier-ignore */
//   src:
//     url('terfensnormbook-webfont.woff2?nh1') format('woff2'),
//     url('terfensnormbook-webfont.woff?nh1') format('woff');
// }

// @font-face {
//   font-family: 'terfens-new'; /* stylelint-disable-line font-family-name-quotes */
//   font-weight: 700;
//   font-style: normal;

//   /* prettier-ignore */
//   src:
//     url('terfensnormbold-webfont.woff2?nh1') format('woff2'),
//     url('terfensnormbold-webfont.woff?nh1') format('woff');
// }
