.image {
  img {
    display: block;
    margin: 0 auto;

    &.l-rounded {
      border-radius: var(--app-outline-radius);
      overflow: hidden;
    }
  }
}
