.section.create-event {
  .section {
    &-content {
      @include media-breakpoint-up('sm') {
        display: flex;
        align-items: flex-start;
      }

      .headline {
        > span {
          &:nth-child(2) {
            margin-left: 2em;
          }
        }
      }

      .content,
      .form {
        flex-grow: 1;
        margin-top: calc(var(--app-outline-width) * 2);

        @include media-breakpoint-up('sm') {
          padding-left: calc(var(--app-outline-width) * 2);
          margin-top: 0;
        }

        @include media-breakpoint-up('lg') {
          padding-left: calc(var(--app-outline-width) * 3);
        }

        @include media-breakpoint-up('xl') {
          padding-left: calc(var(--app-outline-width) * 4);
        }

        @include media-breakpoint-up('xxl') {
          padding-left: calc(var(--app-outline-width) * 5);
        }
      }

      .content {
        align-self: flex-end;
      }

      .form {
        &.is-logged {
          .form-sent {
            margin-left: 0;
            color: color('primary');
            text-align: right;
          }
        }
      }
    }
  }
}
