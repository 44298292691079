.hr {
  width: 15em;
  height: 3.0706em;
  margin: 0 auto calc(var(--app-outline-width) * 3) auto;
  border: none;
  clear: both;
  background-color: var(--current-color);
  mask: url('../../images/vegetables-separator.svg');
  mask-size: contain;

  @include media-breakpoint-up('md') {
    width: 30vw;
    height: 6.1412vw;
  }

  &.c-white {
    background-color: #fff;
  }

  &.l-mb-half {
    margin-bottom: calc(var(--app-outline-width) * 1.5);
  }
}
