/**
 * @see ./_press.scss
 */

.article.event {
  .article {
    &-header {
      //
    }

    &-title {
      margin-bottom: 0;

      & + p {
        margin-bottom: 0;
        font-family: $font-family-italic;
        font-style: italic;
      }
    }

    &-content {
      .spacer {
        flex-grow: 1;
      }

      .date-time-range {
        .dtr-times {
          margin-left: 0.5em;
        }
      }
    }
  }
}
