@font-face {
  font-family: 'icomoon'; /* stylelint-disable-line font-family-name-quotes */
  font-weight: normal;
  font-style: normal;
  src: url('icomoon.eot?nh1');

  /* prettier-ignore */
  src:
    url('icomoon.eot?nh1#iefix') format('embedded-opentype'),
    url('icomoon.woff2?nh1') format('woff2'),
    url('icomoon.ttf?nh1') format('truetype'),
    url('icomoon.woff?nh1') format('woff'),
    url('icomoon.svg?nh1#icomoon') format('svg');
}
