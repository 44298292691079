.no-result {
  text-align: center;

  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
  }

  .hr {
    margin-top: calc(var(--app-outline-width) * 1.5);
  }

  [class*='bg-'] & {
    hr {
      color: color('white');
    }
  }
}
