.hero {
  --hero-padding: calc(var(--app-outline-width) * 3);

  padding: var(--hero-padding);
  border-radius: var(--app-outline-radius);

  @media (orientation: landscape) {
    --hero-padding: calc(var(--app-outline-width) * 2);

    @include media-breakpoint-up('lg') {
      @include media-breakpoint-up('md', 'height') {
        display: flex;
        flex-direction: row;
        height: calc(100vh - var(--app-outline-width) * 2);

        @media (min-aspect-ratio: 2.25) {
          --hero-padding: calc(var(--app-outline-width) * 1.5);
        }

        @media (min-aspect-ratio: 3) {
          --hero-padding: calc(var(--app-outline-width) * 1);
        }
      }
    }
  }

  > div {
    position: relative;

    &:first-child {
      width: 100%;
      padding-bottom: 1.75rem;

      @media (orientation: landscape) {
        @include media-breakpoint-up('lg') {
          @include media-breakpoint-up('md', 'height') {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 45%;
            height: 100%;
            padding-bottom: 0;
          }
        }
      }

      @media (orientation: portrait) {
        @include media-breakpoint-up('md') {
          padding-bottom: 3.5em;
        }
      }
    }

    &:last-child {
      @media (orientation: landscape) {
        height: calc((70vw - var(--app-outline-width) * 2 - var(--hero-padding)) / 1.221);

        @include media-breakpoint-up('lg') {
          @include media-breakpoint-up('md', 'height') {
            position: relative;
            width: 55%;
            height: auto;
          }
        }
      }

      @media (orientation: portrait) {
        @include media-breakpoint-up('md') {
          height: calc((70vw - var(--app-outline-width) * 2 - var(--hero-padding)) / 1.221);
        }
      }
    }
  }

  .hero {
    &-logo {
      width: auto;
      height: 2.4em;

      @include media-breakpoint-up('lg') {
        height: 3.619em;
      }

      @media (orientation: landscape) {
        margin-bottom: 5rem;

        @include media-breakpoint-up('lg') {
          @include media-breakpoint-up('md', 'height') {
            margin-bottom: 0;
          }
        }
      }

      @media (orientation: portrait) {
        @include media-breakpoint-up('xs') {
          margin-bottom: 7rem;
        }
      }
    }

    &-headline {
      font-size: 0.7em;

      @media (orientation: landscape) {
        @include media-breakpoint-up('lg') {
          @include media-breakpoint-up('md', 'height') {
            margin: calc(var(--app-outline-width) * 1.5) 0;
          }

          @include media-breakpoint-up('lg', 'height') {
            margin: calc(var(--app-outline-width) * 2) 0;
            font-size: 2.49vh;

            @media (min-aspect-ratio: 3) {
              font-size: 1em;
            }

            @media (min-aspect-ratio: 4.5) {
              font-size: 0.8em;
            }
          }
        }

        @include media-breakpoint-up('xl', 'height') {
          font-size: 1.1875em;
        }
      }

      @media (orientation: portrait) {
        margin: 2.5rem 0;
      }

      > span:nth-child(2) {
        margin-left: 1em;
      }
    }

    &-content {
      .button {
        display: inline-block;
        margin-top: 2rem;
      }
    }

    &-title {
      margin: 0;
      font-family: $font-family-sans;
      font-size: 1.5238em;
      font-weight: $font-weight-medium;
      text-transform: uppercase;

      @include media-breakpoint-up('xs') {
        font-size: 1.7143em;
      }

      @media (orientation: landscape) {
        white-space: nowrap;
      }
    }

    &-festivals {
      --grid-cols-3col: auto auto auto;
      --grid-rows-3col: repeat(var(--row-count), auto);
      --grid-cols-2col: auto auto;
      --grid-rows-2col: repeat(calc(var(--festivals-count) / 2), auto);

      padding-left: 1em;
      margin-bottom: 0;
      font-size: 1.2381em;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up('xs') {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0 calc(var(--app-outline-width) + 1em);
        grid-template-columns: var(--grid-cols-2col);
        grid-template-rows: var(--grid-rows-2col);
        gap: 0 calc(var(--app-outline-width) + 1em);
      }

      &:not(.has-long-names) {
        @include media-breakpoint-up('sm') {
          grid-template-columns: var(--grid-cols-3col);
          grid-template-rows: var(--grid-rows-3col);
        }
      }

      > li {
        white-space: nowrap;

        &::before {
          font-size: 0.75em;
          transform: translate(-130%, 45%);
        }

        .year {
          font-family: $font-family-italic;
          font-style: italic;
          font-weight: $font-weight-light;
        }
      }

      .link {
        position: relative;
        z-index: 10;
      }
    }

    &-links {
      @include media-breakpoint-up('sm') {
        display: flex;
        gap: var(--hero-padding);
        width: 50%;
      }

      .link {
        display: inline-block;
        margin-top: var(--hero-padding);
        text-decoration: none;
      }

      h3 {
        margin-bottom: 0;
        font-family: $font-family-sans;
        font-size: 1.7143em;
        font-weight: $font-weight-medium;
        text-transform: uppercase;

        @include media-breakpoint-up('xs') {
          white-space: nowrap;
        }
      }

      p {
        font-weight: $font-weight-light;
        text-decoration: underline solid rgba(color('white', false), 0.2);
        text-underline-offset: 0.15em;
        transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        p {
          text-decoration: underline solid rgba(color('white', false), 0);
          text-underline-offset: 0.075em;
        }
      }
    }
  }

  /** Front page hero */
  &.l-app {
    .hero {
      &-headline {
        padding-left: 2.75rem;

        @media (orientation: landscape) {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0.5em 0 0 0;

          @include media-breakpoint-up('lg') {
            @include media-breakpoint-up('md', 'height') {
              position: static;
              top: auto;
              right: auto;
              padding-left: 4.25rem;
            }
          }
        }

        @media (orientation: portrait) {
          margin: 2.5rem 0;

          @include media-breakpoint-up('xs') {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0.5em 0 0 0;
          }
        }
      }

      &-content {
        @media (orientation: landscape) {
          @include media-breakpoint-up('xl') {
            padding-left: 4.25rem;
          }
        }
      }
    }

    /** Scroll down arrow */
    @keyframes hero-scroll-arrow {
      0% {
        transform: translate3d(-50%, -5%, 0);
      }

      100% {
        transform: translate3d(-50%, 0, 0);
      }
    }

    &::before,
    &::after {
      @include icon('\e908', 1.5em, color('white'));

      display: inline-block;
      position: absolute;
      right: 0;
      bottom: calc(var(--app-outline-width) - 0.2em);
      z-index: 101;
      transform: translateX(-50%);
      transition: opacity 0.25s ease-out;

      @media (prefers-reduced-motion: no-preference) {
        animation: hero-scroll-arrow 2.25s ease-in infinite alternate backwards;
      }

      @include media-breakpoint-up('lg') {
        @include media-breakpoint-up('md', 'height') {
          right: auto;
          left: 50%;
          bottom: -0.2em;
          color: color('primary');
        }
      }
    }

    &::before {
      bottom: var(--app-outline-width);

      @include media-breakpoint-up('lg') {
        @include media-breakpoint-up('md', 'height') {
          bottom: 0;
        }
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: hero-scroll-arrow 2.25s ease-in infinite alternate backwards 1.125s;
      }
    }
  }

  /** Festivals menu hero */
  &.l-festivals {
    min-height: 100%;

    > div {
      &:first-child {
        @media (orientation: landscape) {
          justify-content: center;
        }
      }
    }

    .hero {
      &-headline {
        font-size: 1em;

        @media (orientation: landscape) {
          @include media-breakpoint-up('lg') {
            @include media-breakpoint-up('md', 'height') {
              margin: 0;
              flex-grow: 1;
              font-size: 1.1875em;
            }
          }
        }

        @media (orientation: portrait) {
          margin: 1.5rem 0;
        }
      }

      &-festivals {
        @media (orientation: landscape) {
          margin: var(--hero-padding) 0 0 0;

          @include media-breakpoint-up('lg') {
            @include media-breakpoint-up('md', 'height') {
              grid-template-columns: var(--grid-cols-2col);
              grid-template-rows: var(--grid-rows-2col);

              &:not(.has-long-names) {
                @media (min-aspect-ratio: 2) {
                  grid-template-columns: var(--grid-cols-3col);
                  grid-template-rows: var(--grid-rows-3col);
                }
              }
            }
          }
        }
      }
    }
  }
}

/** Scroll down arrow */
.header.is-scrolled + .template .hero.l-app::before,
.header.is-scrolled + .template .hero.l-app::after {
  opacity: 0;
}
