.cookie {
  @media (prefers-reduced-motion: no-preference) {
    .cookie-dot {
      &-1 {
        animation: flicker 7.5s ease-in 0s infinite;
      }

      &-2 {
        animation: flicker 7.5s ease-in 1.5s infinite;
      }

      &-3 {
        animation: flicker 7.5s ease-in 2.5s infinite;
      }
    }
  }
}
