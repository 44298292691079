[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important; // stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Don't forget to add the icon to the PropTypes of the <Icon> component when you add an icon
 */

.icon-facebook::before,
.icon-f::before {
  content: '\e900';
}

.icon-instagram::before,
.icon-i::before {
  content: '\e901';
}

.icon-linkedin::before,
.icon-l::before {
  content: '\e902';
}

.icon-twitter::before,
.icon-t::before {
  content: '\e903';
}

.icon-youtube::before,
.icon-y::before {
  content: '\e904';
}

.icon-bars::before {
  content: '\2630';
}

.icon-xmark::before {
  content: '\d7';
}

.icon-xmark-bold::before {
  content: '\1f7ab';
}

.icon-check::before {
  content: '\2714';
}

.icon-plus::before {
  content: '\2b';
}

.icon-logo::before {
  content: '\e905';
}

.icon-logo-outline::before {
  content: '\e906';
}

.icon-blank::before {
  content: '\e907';
}

.icon-chevron-down-light::before {
  content: '\e908';
}

.icon-chevron-down::before {
  content: '\e909';
}

.icon-chevron-down-bold::before {
  content: '\e90b';
}

.icon-chevron-right-light::before {
  content: '\e90a';
}

.icon-chevron-right::before {
  content: '\e90c';
}

.icon-chevron-right-bold::before {
  content: '\e90d';
}

.icon-play::before {
  content: '\25b6';
}

.icon-filters::before {
  content: '\10c4';
}

.icon-magnifier::before {
  content: '\1f50d';
}

.icon-map::before {
  content: '\1f5fa';
}

.icon-clock::before {
  content: '\1f553';
}

.icon-map-marker::before {
  content: '\1f4cc';
}

.icon-promoter::before {
  content: '\1f9cd';
}

.icon-arrow-left::before {
  content: '\1f844';
}

.icon-arrow-right::before {
  content: '\1f846';
}

.icon-calendar::before {
  content: '\1f4c5';
}

.icon-email::before {
  content: '\2709';
}

.icon-website::before {
  content: '\1f517';
}

.icon-tel::before {
  content: '\1f4de';
}

.icon-user::before {
  content: '\1f464';
}

.icon-wallet::before {
  content: '\1f45b';
}

.icon-exclamation::before {
  content: '\2757';
}

.icon-language::before {
  content: '\e90e';
}

.icon-heart::before {
  content: '\2661';
}

.icon-tickets::before {
  content: '\e90f';
}
