.headline {
  --indent-l1: 0;
  --indent-l2: 0;
  --indent-l3: 0;

  margin: 0;
  font-size: 0.85em;
  line-height: 1.85em;
  text-transform: none;

  @include media-breakpoint-up('xxs') {
    font-size: 1em;
  }

  > span {
    display: block;
    white-space: nowrap;

    &:nth-child(1) {
      margin-left: var(--indent-l1);
    }

    &:nth-child(2) {
      margin-left: var(--indent-l2);
    }

    &:nth-child(3) {
      margin-left: var(--indent-l3);
    }
  }

  [class^='serif'] {
    font-family: $font-family-serif;
    font-size: 3.0476em;
  }

  .serif {
    font-weight: $font-weight-normal;
  }

  .serif-bold {
    font-weight: $font-weight-bold;
  }

  .serif-semibold {
    font-weight: $font-weight-semibold;
  }

  [class^='sans'] {
    font-family: $font-family-italic;
    font-size: 3.0952em;
    font-style: italic;
  }

  .sans {
    font-weight: $font-weight-normal;
  }

  .sans-light {
    display: inline-block;
    font-weight: $font-weight-light;
    font-size: 1.4286em;
    vertical-align: super;
    transform: translateY(-0.3em);

    &:last-child {
      vertical-align: sub;
      transform: translateY(-0.15em);
    }
  }

  .sans-bold {
    font-weight: $font-weight-bold;
  }

  &.l-inline {
    white-space: nowrap;

    > span {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.2em;
      }
    }
  }
}

p.headline {
  font-size: 0.5em;
}
